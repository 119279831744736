import React, { useState, useEffect, useRef } from "react";
import Plot from 'react-plotly.js';
import Plotly from 'plotly.js-dist';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Box,
  Button,
  Checkbox,
  ButtonGroup,
  Text,
  useToast,
  FormControl,
  Slider,
  HStack,
  FormLabel,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  VStack,
} from "@chakra-ui/react";
import { getPlotData } from "../modules/JobApiService";

const PlotModal = ({ isOpen, onClose, selectedGraph }) => {
  const [plotData, setPlotData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showLabels, setShowLabels] = useState(false);
  const [selectedSamples, setSelectedSamples] = useState({});
  const [error, setError] = useState(null);

    const [plotHeight, setPlotHeight] = useState(400);
    const [pointSize, setPointSize] = useState(12);
  const toast = useToast();

  const plotRef = useRef(null);

  // Fetch plot data when modal opens
  useEffect(() => {
    const fetchData = async () => {
      if (isOpen && selectedGraph.setId) {
        try {
          setLoading(true);
          const data = await getPlotData(selectedGraph.setId);
          setPlotData(data);

          // Initialize selected samples from results
          if (selectedGraph.plotting) {
            const samples = {};
            // Parse the plotting data if it's a string
            const plottingData =
              typeof selectedGraph.plotting === "string"
                ? JSON.parse(selectedGraph.plotting)
                : selectedGraph.plotting;

            if (Array.isArray(plottingData)) {
              plottingData.forEach((point) => {
                samples[point.sample] = true;
              });
              setSelectedSamples(samples);
            }
          }
        } catch (err) {
          console.error("Error:", err); // Add this for debugging
          setError("Failed to load plot data");
          toast({
            title: "Error loading plot data",
            status: "error",
            duration: 5000,
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [isOpen, selectedGraph.setId]);

  // Prepare plot data for Plotly
  const getPlotlyData = () => {
    if (!plotData) return [];

    const traces = [];

    // Add typical data
    if (plotData.typical && plotData.typical.length > 0) {
      traces.push({
        name: "Typical",
        x: plotData.typical.map((p) => parseFloat(p.x)), // Changed from X to x
        y: plotData.typical.map((p) => parseFloat(p.y)), // Changed from Y to y
        ...(selectedGraph.type === "3d" && {
          z: plotData.typical.map((p) => parseFloat(p.z)), // Changed from Z to z
          type: "scatter3d",
        }),
        mode: "markers",
        marker: {
          color: "rgb(0, 128, 0)", // more specific green
          size: pointSize,
          line: {
            color: "rgb(0, 100, 0)", // darker green
            width: 1,
          },
          opacity: 0.7,
        },
        showlegend: true,
        type: selectedGraph.type === "3d" ? "scatter3d" : "scatter",
      });
    }

    // Add atypical data
    if (plotData.atypical && plotData.atypical.length > 0) {
      traces.push({
        name: "Atypical",
        x: plotData.atypical.map((p) => parseFloat(p.x)), // Changed from X to x
        y: plotData.atypical.map((p) => parseFloat(p.y)), // Changed from Y to y
        ...(selectedGraph.type === "3d" && {
          z: plotData.atypical.map((p) => parseFloat(p.z)), // Changed from Z to z
          type: "scatter3d",
        }),
        mode: "markers",
        marker: {
          color: "rgb(255, 0, 0)", // more specific red
          size: pointSize,
          line: {
            color: "rgb(139, 0, 0)", // darker red
            width: 1,
          },
          opacity: 0.7,
        },
        showlegend: true,
        type: selectedGraph.type === "3d" ? "scatter3d" : "scatter",
      });
    }

    // Add results data (keep uppercase X, Y, Z for this since it comes from a different source)
    if (selectedGraph.plotting) {
      const plottingData =
        typeof selectedGraph.plotting === "string"
          ? JSON.parse(selectedGraph.plotting)
          : selectedGraph.plotting;

      if (Array.isArray(plottingData)) {
        plottingData.forEach((point) => {
          if (selectedSamples[point.sample]) {
            traces.push({
              name: point.sample,
              x: [parseFloat(point.X)],
              y: [parseFloat(point.Y)],
              ...(selectedGraph.type === "3d" && {
                z: [parseFloat(point.Z)],
                type: "scatter3d",
              }),
              mode: showLabels ? "markers+text" : "markers",
              text: showLabels ? point.sample : "",
              textposition: "top center",
              marker: {
                color: "rgb(0, 0, 255)", // more specific blue
                size: pointSize,
                line: {
                  color: "rgb(0, 0, 139)", // darker blue
                  width: 1.5,
                },
                opacity: 0.9,
              },
              showlegend: true,
              type: selectedGraph.type === "3d" ? "scatter3d" : "scatter",
            });
          }
        });
      }
    }

    return traces;
  };

  // Layout configuration
  const layout = {
    showlegend: true,
    legend: {
      x: 0,
      y: -0.2,
      orientation: "h",
    },
    margin: { l: 50, r: 50, t: 50, b: 100 },
    hovermode: "closest",
    grid: { rows: 1, columns: 1, pattern: "independent" },
    ...(selectedGraph.type === "3d"
      ? {
          scene: {
            xaxis: { gridcolor: "#f0f0f0" },
            yaxis: { gridcolor: "#f0f0f0" },
            zaxis: { gridcolor: "#f0f0f0" },
          },
        }
      : {
          xaxis: { gridcolor: "#f0f0f0" },
          yaxis: { gridcolor: "#f0f0f0" },
        }),
  };

  // Config for plot interactions
  const config = {
    responsive: true,
    displaylogo: false,
    modeBarButtonsToAdd: ["toImage"],
    modeBarButtonsToRemove: ["lasso2d", "select2d"],
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="90vw">
        <ModalHeader>
          
          {selectedGraph.title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody minH="600px">
          <Flex direction="column" gap={4}>
            {/* Controls */}
            <ButtonGroup size="sm" isAttached variant="outline">
              <Button onClick={() => setShowLabels(!showLabels)}>
                {showLabels ? "Hide Sample Labels" : "Show Sample Labels"}
              </Button>
              {selectedGraph.type === "3d" && (
                <Button
                  onClick={() => {
                    // Reset camera to initial view
                    const plotDiv = document.getElementById("plot");
                    if (plotDiv) {
                      Plotly.relayout(plotDiv, {
                        "scene.camera": {
                          eye: { x: 1.5, y: 1.5, z: 1.5 },
                          center: { x: 0, y: 0, z: 0 },
                          up: { x: 0, y: 0, z: 1 },
                        },
                      });
                    }
                  }}
                >
                  Reset View
                </Button>
              )}
            </ButtonGroup>
            <HStack>
            <FormControl>
                    <HStack spacing={4} align="center">
                      <FormLabel htmlFor="plotHeight" mb="0">
                        Plot Height
                      </FormLabel>
                      <Slider
                        id="plotHeight"
                        min={400}
                        max={1000}
                        step={50}
                        value={plotHeight}
                        onChange={setPlotHeight}
                        w="200px"
                      >
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                      <Text>{plotHeight}px</Text>
                    </HStack>
                  </FormControl>
                  <FormControl>
                    <HStack spacing={4} align="center">
                      <FormLabel htmlFor="pointSize" mb="0">
                        Data Point Size
                      </FormLabel>
                      <Slider
                        id="plotHeight"
                        min={1}
                        max={25}
                        step={1}
                        value={pointSize}
                        onChange={setPointSize}
                        w="200px"
                      >
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                      <Text>{pointSize}px</Text>
                    </HStack>
                  </FormControl>
</HStack>
            {/* Plot */}
            <Box h={plotHeight} ref={plotRef}>
              {!loading && (
                <Plot
                  id="plot"
                  data={getPlotlyData()}
                  layout={{
                    ...layout,
                    autosize: true,
                    width: undefined, // Let it be responsive
                    height: undefined, // Let it be responsive
                    showlegend: true,
                    legend: {
                      x: 0,
                      y: -0.2,
                      orientation: "h",
                      yanchor: "bottom",
                      xanchor: "left",
                    },
                    margin: { l: 50, r: 50, t: 50, b: 100 },
                    hovermode: "closest",
                    plot_bgcolor: "rgb(255, 255, 255)",
                    paper_bgcolor: "rgb(255, 255, 255)",
                    ...(selectedGraph.type === "3d"
                      ? {
                          scene: {
                            xaxis: { gridcolor: "#f0f0f0", showgrid: true },
                            yaxis: { gridcolor: "#f0f0f0", showgrid: true },
                            zaxis: { gridcolor: "#f0f0f0", showgrid: true },
                          },
                        }
                      : {
                          xaxis: {
                            gridcolor: "#f0f0f0",
                            showgrid: true,
                            zeroline: true,
                            zerolinecolor: "#969696",
                            zerolinewidth: 1,
                          },
                          yaxis: {
                            gridcolor: "#f0f0f0",
                            showgrid: true,
                            zeroline: true,
                            zerolinecolor: "#969696",
                            zerolinewidth: 1,
                          },
                        }),
                  }}
                  config={{
                    responsive: true,
                    displaylogo: false,
                    modeBarButtonsToAdd: ["toImage"],
                    modeBarButtonsToRemove: ["lasso2d", "select2d"],
                    toImageButtonOptions: {
                      format: "png",
                      filename: `plot_set_${selectedGraph.setId}`,
                      height: plotHeight,
                      width: plotRef.current?.clientWidth || 1200,
                      scale: 2,
                    },
                  }}
                  style={{ width: "100%", height: "100%" }}
                  useResizeHandler={true}
                />
              )}
            </Box>

            {/* Sample Checkboxes */}
            {selectedGraph.plotting && (
              <Box mt={4}>
                <Text fontWeight="bold" mb={2}>
                  Select Samples to Display:
                </Text>
                <Flex wrap="wrap" gap={4}>
                  {(typeof selectedGraph.plotting === "string"
                    ? JSON.parse(selectedGraph.plotting)
                    : selectedGraph.plotting
                  ).map((point) => (
                    <Checkbox
                      key={point.sample}
                      isChecked={selectedSamples[point.sample]}
                      onChange={(e) => {
                        setSelectedSamples({
                          ...selectedSamples,
                          [point.sample]: e.target.checked,
                        });
                      }}
                    >
                      {point.sample}
                    </Checkbox>
                  ))}
                </Flex>
              </Box>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PlotModal;
