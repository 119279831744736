// src/components/dashboard/widgets/NavigationWidget.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  VStack,
  HStack,
  Checkbox,
  Flex,
  useColorModeValue,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Divider,
  Portal,
  SimpleGrid,
} from '@chakra-ui/react';
import {
  SettingsIcon,
  ViewIcon,
  AddIcon,
  TriangleUpIcon,
  DragHandleIcon,
} from '@chakra-ui/icons';
import { Move3d, ChartScatter, CirclePlus, TrendingUpDown, History, File } from "lucide-react"
import { useDashboard } from '../../../modules/dashboard/DashboardContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Define available shortcuts
const AVAILABLE_SHORTCUTS = [
  {
    id: 'predictions',
    title: 'View Predictions',
    description: 'Browse and manage predictions',
    path: '/portal/predictions',
    icon: TrendingUpDown,
    color: 'blue'
  },
  {
    id: 'new-prediction',
    title: 'New Prediction',
    description: 'Create a new prediction',
    path: '/portal/predictions/new',
    icon: CirclePlus,
    color: 'green'
  },
  {
    id: 'plot3d',
    title: '3D Graph',
    description: 'Generate 3D visualizations',
    path: '/portal/plot3D',
    icon: Move3d,
    color: 'purple'
  },
  {
    id: 'plot2d',
    title: '2D Graph',
    description: 'Generate 2D visualizations',
    path: '/portal/plot',
    icon: ChartScatter,
    color: 'red'
  },
  {
    id: 'average',
    title: 'Average Spectra',
    description: 'Generate average spectra files',
    path: '/portal/average',
    icon: File,
    color: 'orange'
  },
  {
    id: 'history',
    title: 'Tool History',
    description: 'View tooling history',
    path: '/portal/history',
    icon: History,
    color: 'pink'
  },
];

const NavigationButton = ({ shortcut, onClick }) => {
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'white');
  const descriptionColor = useColorModeValue('gray.500', 'gray.400');
  const IconComponent = shortcut.icon;

  return (
    <Flex
      onClick={onClick}
      direction="column"
      align="center"
      justify="center"
      bg={bgColor}
      p={4}
      borderRadius="xl"
      border="1px solid"
      borderColor={borderColor}
      cursor="pointer"
      transition="all 0.2s"
      h="full"
      w="full"
      role="group"
      _hover={{
        transform: 'translateY(-2px)',
        shadow: 'lg',
        borderColor: `${shortcut.color}.400`,
      }}
    >
      <Box
        bg={`${shortcut.color}.50`}
        color={`${shortcut.color}.500`}
        p={3}
        borderRadius="lg"
        mb={3}
        transition="all 0.2s"
        _groupHover={{
          bg: `${shortcut.color}.500`,
          color: 'white',
        }}
      >
        <IconComponent />
      </Box>
      <Text
        fontWeight="semibold"
        fontSize="sm"
        textAlign="center"
        mb={1}
        color={textColor}
      >
        {shortcut.title}
      </Text>
      <Text
        fontSize="xs"
        textAlign="center"
        color={descriptionColor}
        noOfLines={2}
      >
        {shortcut.description}
      </Text>
    </Flex>
  );
};

const NavigationSettings = ({ settings, onUpdate }) => {
  const [localSettings, setLocalSettings] = useState(settings);
  const settingsBg = useColorModeValue('gray.50', 'gray.700');

  const handleChange = (key, value) => {
    const newSettings = { ...localSettings, [key]: value };
    setLocalSettings(newSettings);
    onUpdate(newSettings);
  };

  return (
    <VStack spacing={4} align="stretch">
      <Box>
        <Text mb={2} fontSize="sm" fontWeight="medium">Grid Columns</Text>
        <NumberInput
          size="sm"
          min={1}
          max={4}
          value={localSettings.columns}
          onChange={(_, value) => handleChange('columns', value)}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </Box>
      
      <Box>
        <Text mb={2} fontSize="sm" fontWeight="medium">Button Size</Text>
        <Select
          size="sm"
          value={localSettings.buttonSize}
          onChange={(e) => handleChange('buttonSize', e.target.value)}
        >
          <option value="compact">Compact</option>
          <option value="normal">Normal</option>
          <option value="large">Large</option>
        </Select>
      </Box>

      <Divider my={2} />
      
      <Box>
        <Text mb={2} fontSize="sm" fontWeight="medium">Active Shortcuts</Text>
        <Text fontSize="xs" color="gray.500" mb={2}>
          Drag to reorder. Click checkbox to toggle visibility.
        </Text>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) return;
            
            const items = Array.from(localSettings.enabledShortcuts);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);
            
            handleChange('enabledShortcuts', items);
          }}
        >
          <Droppable droppableId="shortcuts">
            {(provided) => (
              <VStack
                {...provided.droppableProps}
                ref={provided.innerRef}
                align="stretch"
                spacing={2}
              >
                {AVAILABLE_SHORTCUTS.map((shortcut, index) => (
                  <Draggable
                    key={shortcut.id}
                    draggableId={shortcut.id}
                    index={index}
                  >
                    {(provided) => (
                      <HStack
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        bg={settingsBg}
                        p={2}
                        borderRadius="md"
                      >
                        <Box {...provided.dragHandleProps}>
                          <DragHandleIcon color="gray.400" />
                        </Box>
                        <Checkbox
                          isChecked={localSettings.enabledShortcuts.includes(shortcut.id)}
                          onChange={() => {
                            const newEnabled = localSettings.enabledShortcuts.includes(shortcut.id)
                              ? localSettings.enabledShortcuts.filter(id => id !== shortcut.id)
                              : [...localSettings.enabledShortcuts, shortcut.id];
                            handleChange('enabledShortcuts', newEnabled);
                          }}
                        >
                          {shortcut.title}
                        </Checkbox>
                      </HStack>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </VStack>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </VStack>
  );
};

const NavigationWidget = ({ widget }) => {
  const navigate = useNavigate();
  const { updateWidgetSettings } = useDashboard();
  const emptyStateBg = useColorModeValue('gray.50', 'gray.700');
  const emptyStateColor = useColorModeValue('gray.500', 'gray.400');
  
  // Default settings
  const settings = {
    enabledShortcuts: AVAILABLE_SHORTCUTS.map(s => s.id),
    columns: 3,
    buttonSize: 'normal',
    ...widget.settings
  };

  const handleSettingsUpdate = (newSettings) => {
    updateWidgetSettings(widget.instanceId, newSettings);
  };

  // Get size class based on settings
  const getSizeClass = () => {
    switch (settings.buttonSize) {
      case 'compact': return '120px';
      case 'large': return '180px';
      default: return '150px';
    }
  };

  const activeShortcuts = AVAILABLE_SHORTCUTS.filter(
    shortcut => settings.enabledShortcuts.includes(shortcut.id)
  ).sort((a, b) => {
    return settings.enabledShortcuts.indexOf(a.id) - settings.enabledShortcuts.indexOf(b.id);
  });

  return (
    <Box>
      <HStack justify="space-between" align="center" mb={4}>
        <Text fontWeight="medium" fontSize="lg">Shortcuts</Text>
        <Menu placement="bottom-end" strategy="fixed">
          <MenuButton
            as={IconButton}
            icon={<SettingsIcon />}
            variant="ghost"
            size="sm"
            aria-label="Settings"
          />
          <Portal>
            <MenuList 
              p={4} 
              minW="300px" 
              zIndex={9999} 
              boxShadow="xl"
              position="fixed"
              maxH="none"
            >
              <NavigationSettings
                settings={settings}
                onUpdate={handleSettingsUpdate}
              />
            </MenuList>
          </Portal>
        </Menu>
      </HStack>

      <SimpleGrid
        columns={settings.columns}
        spacing={4}
        w="100%"
      >
        {activeShortcuts.map((shortcut, index) => (
          <Box
            key={shortcut.id}
            w={getSizeClass()}
            h={getSizeClass()}
            minW={0}
          >
            <NavigationButton
              shortcut={shortcut}
              onClick={() => navigate(shortcut.path)}
            />
          </Box>
        ))}
      </SimpleGrid>

      {activeShortcuts.length === 0 && (
        <Box
          textAlign="center"
          py={8}
          color={emptyStateColor}
          bg={emptyStateBg}
          borderRadius="lg"
        >
          <Text>No shortcuts selected. Use the settings to add shortcuts.</Text>
        </Box>
      )}
    </Box>
  );
};

export default NavigationWidget;