import { Outlet, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useRef } from "react";
import {
  Box,
  Center,
  Grid,
  GridItem,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  VStack,
  Text,
  Divider,
  useDisclosure,
  keyframes,
  Tag,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import {
  LayoutDashboard,
  TrendingUpDown,
  CirclePlus,
  Move3d,
  ChartScatter,
  File,
  History,
  Terminal,
  Settings,
  LogOut,
  Menu,
  ChevronRight,
} from "lucide-react";
import BiaLogo from "../assets/Bia.jpg";
import { useAuth } from "../context/AuthContext";

// Keyframes for hover animation
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
`;

// Navigation group component with animation
const NavGroup = ({ title, children, color = "gray.500" }) => (
  <Box mb={4} role="group">
    <Text 
      fontSize="sm" 
      color={color} 
      fontWeight="bold" 
      mb={2}
      transition="color 0.2s"
      _groupHover={{ color: `${color}.600` }}
    >
      {title}
    </Text>
    <VStack align="stretch" spacing={2}>
      {children}
    </VStack>
  </Box>
);

// Enhanced navigation item component
const NavItem = ({ label, icon: Icon, onClick, isActive, color = "gray.500", isDev }) => {
  const ItemIcon = Icon || LayoutDashboard;
  
  return (
    <Box
      as="button"
      display="flex"
      alignItems="center"
      w="full"
      p={3}
      transition="all 0.2s"
      borderLeftWidth="3px"
      borderLeftColor={isActive ? color : "transparent"}
      bg={isActive ? `${color}.50` : "transparent"}
      _hover={{
        bg: `${color}.50`,
        transform: "translateX(2px)",
      }}
      onClick={onClick}
      position="relative"
    >
      <Box color={isActive ? color : "gray.500"} mr={3}>
        <ItemIcon size={20} />
      </Box>
      <Text color={isActive ? "black" : "gray.700"}>
        {label}
      </Text>
      {isDev && (
        <Tag
          size="sm"
          variant="subtle"
          colorScheme="gray"
          ml={2}
          fontFamily="mono"
          display="flex"
          alignItems="center"
          gap={1}
        >
          <Terminal size={12} />
          dev
        </Tag>
      )}
    </Box>
  );
};

export default function Portal() {
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut } = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [showDevTools, setShowDevTools] = useState(false);
  const clickCount = useRef(0);
  const clickTimer = useRef(null);

  const handleLogoClick = () => {
    clickCount.current += 1;
    if (clickTimer.current) clearTimeout(clickTimer.current);
    
    clickTimer.current = setTimeout(() => {
      clickCount.current = 0;
    }, 800);

    if (clickCount.current === 3) {
      setShowDevTools(true);
      clickCount.current = 0;
      clearTimeout(clickTimer.current);
    }
  };

  // Path mapping for breadcrumbs
  const pathMap = {
    'dashboard': { name: 'Dashboard', icon: LayoutDashboard },
    'predictions': { name: 'Predictions', icon: TrendingUpDown },
    'predictions/new': { name: 'New Prediction', icon: CirclePlus },
    'plot3D': { name: '3D Graph', icon: Move3d },
    'plot': { name: '2D Graph', icon: ChartScatter },
    'average': { name: 'Average Spectra', icon: File },
    'history': { name: 'Tool History', icon: History },
    'dev-tools': { name: 'Dev Tools', icon: Terminal },
    'admin': { name: 'Administration', icon: Settings },
  };

  // Get breadcrumb items from current path
  const getBreadcrumbs = () => {
    const currentPath = location.pathname.split('/').filter(Boolean);
    const items = [];
    let path = '';

    for (const segment of currentPath) {
      path += `/${segment}`;
      if (pathMap[segment]) {
        const IconComponent = pathMap[segment].icon;
        items.push({
          path,
          name: pathMap[segment].name,
          icon: IconComponent,
        });
      }
    }

    return items;
  };

  const isCurrentPath = (path) => location.pathname === path;

  const handleNavigation = (path) => {
    navigate(path);
    onClose();
  };

  const handleLogout = async () => {
    try {
      await signOut();
      setShowDevTools(false);
      navigate("/portal/dashboard");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box width="95%" margin="auto" axis="both" marginTop={2}>
      <Grid templateColumns="auto 1fr auto" gap={6}>
        <GridItem display="flex" alignItems="center" h="16">
          <Box display="flex" alignItems="center" gap={4}>
            <IconButton
              aria-label="Open Menu"
              icon={<Menu />}
              onClick={onOpen}
              variant="ghost"
              height="40px"
              _hover={{
                animation: `${pulseAnimation} 1s ease-in-out`
              }}
            />
            <a 
              href="/portal/dashboard" 
              style={{ display: 'flex', alignItems: 'center' }}
              title="Dashboard" 
              onClick={(e) => {
                e.preventDefault();
                handleLogoClick();
                navigate("/portal/dashboard");
              }}
            >
              <img src={BiaLogo} width={200} alt="Logo" style={{ display: 'block' }} />
            </a>
          </Box>
        </GridItem>
        
        <GridItem display="flex" alignItems="center">
          <Box flex="1">
            <Breadcrumb 
              spacing="8px" 
              separator={<ChevronRight size={16} color="gray" />}
            >
              {getBreadcrumbs().map((item, index) => (
                <BreadcrumbItem key={item.path} isCurrentPage={index === getBreadcrumbs().length - 1}>
                  <BreadcrumbLink
                    href={item.path}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(item.path);
                    }}
                    display="flex"
                    alignItems="center"
                    color={index === getBreadcrumbs().length - 1 ? "blue.500" : "gray.500"}
                    _hover={{ textDecoration: 'none', color: "blue.400" }}
                  >
                    <Box mr={2} display="flex" alignItems="center">
                      <item.icon size={16} />
                    </Box>
                    {item.name}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              ))}
            </Breadcrumb>
          </Box>
        </GridItem>
        
        <GridItem />
      </Grid>

      <Drawer 
        isOpen={isOpen} 
        placement="left" 
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay backdropFilter="blur(2px)" />
        <DrawerContent>
          <DrawerHeader 
            borderBottomWidth="1px"
            bgGradient="linear(to-r, gray.50, white)"
            py={3}
            fontSize="md"
          >
            <DrawerCloseButton />
            Navigation Menu
          </DrawerHeader>
          
          <DrawerBody>
            <VStack spacing={4} align="stretch" pt={4}>
              <NavGroup title="Dashboard">
                <NavItem
                  label="Dashboard"
                  icon={LayoutDashboard}
                  onClick={() => handleNavigation("/portal/dashboard")}
                  isActive={isCurrentPath("/portal/dashboard")}
                  color="blue.500"
                />
              </NavGroup>

              <NavGroup title="Predictions">
                <NavItem
                  label="View Predictions"
                  icon={TrendingUpDown}
                  onClick={() => handleNavigation("/portal/predictions")}
                  isActive={isCurrentPath("/portal/predictions")}
                  color="blue"
                />
                <NavItem
                  label="New Prediction"
                  icon={CirclePlus}
                  onClick={() => handleNavigation("/portal/predictions/new")}
                  isActive={isCurrentPath("/portal/predictions/new")}
                  color="green"
                />
              </NavGroup>

              <NavGroup title="Tooling">
                <NavItem
                  label="3D Graph"
                  icon={Move3d}
                  onClick={() => handleNavigation("/portal/plot3D")}
                  isActive={isCurrentPath("/portal/plot3D")}
                  color="purple"
                />
                <NavItem
                  label="2D Graph"
                  icon={ChartScatter}
                  onClick={() => handleNavigation("/portal/plot")}
                  isActive={isCurrentPath("/portal/plot")}
                  color="red"
                />
                <NavItem
                  label="Average Spectra"
                  icon={File}
                  onClick={() => handleNavigation("/portal/average")}
                  isActive={isCurrentPath("/portal/average")}
                  color="orange"
                />
              </NavGroup>

              {showDevTools && (
                <NavItem
                  label="Dev Tools"
                  icon={Terminal}
                  onClick={() => handleNavigation("/portal/dev-tools")}
                  isActive={isCurrentPath("/portal/dev-tools")}
                  color="gray.700"
                  isDev={true}
                />
              )}

              <Divider />

              <NavItem
                label="Administration"
                icon={Settings}
                onClick={() => handleNavigation("/portal/admin")}
                isActive={isCurrentPath("/portal/admin")}
                color="gray.700"
              />

              <NavItem
                label="Logout"
                icon={LogOut}
                onClick={handleLogout}
                color="red.500"
              />
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Center bg="white" mt={6}>
        <Outlet />
      </Center>
    </Box>
  );
}