import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  Skeleton,
  Text,
  VStack,
  HStack,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
} from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const JobSuccessRateWidget = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('30');

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/dashboard/job-success-rate`, {
        params: { days: timeRange }
      });
      setData(response.data);
    } catch (err) {
      setError('Failed to load job success rate');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [timeRange]);

  if (loading) {
    return <Skeleton height="200px" />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  const plotData = [{
    x: data.map(d => new Date(d.date)),
    y: data.map(d => d.success_rate),
    type: 'scatter',
    mode: 'lines',
    name: 'Success Rate',
    line: {
      color: '#22C55E',
      width: 2
    }
  }];

  const layout = {
    autosize: true,
    height: 200,
    margin: {
      l: 50,
      r: 20,
      t: 20,
      b: 40
    },
    xaxis: {
      type: 'date',
      tickformat: '%d %b',
      showgrid: true,
      gridcolor: '#E2E8F0'
    },
    yaxis: {
      range: [0, 100],
      ticksuffix: '%',
      showgrid: true,
      gridcolor: '#E2E8F0',
      zeroline: false
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    showlegend: false,
    hovermode: 'x unified'
  };

  const config = {
    displayModeBar: false,
    responsive: true
  };

  // Calculate overall statistics
  const totalJobs = data.reduce((acc, curr) => acc + curr.total_jobs, 0);
  const totalSuccessful = data.reduce((acc, curr) => acc + curr.successful_jobs, 0);
  const overallSuccessRate = totalJobs > 0 ? (totalSuccessful / totalJobs) * 100 : 0;

  return (
    <VStack spacing={4} align="stretch">
      <HStack spacing={4}>
        <Text>Time Range:</Text>
        <Select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          size="sm"
          w="150px"
        >
          <option value="7">Last 7 days</option>
          <option value="30">Last 30 days</option>
          <option value="90">Last 90 days</option>
        </Select>
      </HStack>

      <HStack spacing={8} justify="center">
        <Stat>
          <StatLabel>Total Jobs</StatLabel>
          <StatNumber>{totalJobs.toLocaleString()}</StatNumber>
        </Stat>
        <Stat>
          <StatLabel>Success Rate</StatLabel>
          <StatNumber color={overallSuccessRate >= 90 ? 'green.500' : 'orange.500'}>
            {overallSuccessRate.toFixed(1)}%
          </StatNumber>
          <StatHelpText>{totalSuccessful.toLocaleString()} successful</StatHelpText>
        </Stat>
      </HStack>

      <Box>
        <Plot
          data={plotData}
          layout={layout}
          config={config}
          style={{ width: '100%' }}
        />
      </Box>
    </VStack>
  );
};

export default JobSuccessRateWidget;