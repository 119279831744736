// src/components/Dashboard.jsx
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardProvider, useDashboard } from "../modules/dashboard/DashboardContext";
import DashboardLayout from "../components/dashboard/DashboardLayout";

// Wrapper component to handle initial loading
const DashboardContent = () => {
  const { loadDashboardConfig } = useDashboard();

  useEffect(() => {
    loadDashboardConfig();
  }, []);

  return <DashboardLayout />;
};

export default function Dashboard() {
  const navigate = useNavigate();

  return (
    <DashboardProvider>
      <DashboardContent />
    </DashboardProvider>
  );
}