import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import JobsPage from "../components/JobsPage";

export default function Predictions() {
  const navigate = useNavigate();

  return (
    <div>
      <VStack spacing="24px">
        <Box marginY={30}>
          <Center border="none" bg="white">
            <VStack spacing="2px">
              <Container margin={"20px"} padding={"1px"} textAlign={"center"}>
                This page is for creating and viewing predictions of
                commodities. The table below shows the predictions you have
                submitted for processing.
              </Container>
              <Flex width="full" alignItems="center">
                <HStack spacing={2}>
                  {/* <Button
                    leftIcon={<Search2Icon />}
                    colorScheme="blue"
                    variant="outline"
                  >
                    Filter
                  </Button> */}
                </HStack>
                <Button
                  leftIcon={<AddIcon />}
                  ml="auto"
                  colorScheme="green"
                  onClick={() => navigate("/portal/predictions/new")}
                >
                  New Prediction
                </Button>
              </Flex>
              <JobsPage />
            </VStack>
          </Center>
        </Box>
      </VStack>
    </div>
  );
}
