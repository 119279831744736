import {Box, Button, Center, Grid, GridItem, Image, Input, InputGroup, InputLeftAddon, Select, SimpleGrid, Stack, Text, VStack} from "@chakra-ui/react";
import Image2 from "../assets/Image2-500.jpg";
import * as React from "react";
import {CheckIcon, RepeatIcon} from "@chakra-ui/icons";
import { useForm, SubmitHandler } from "react-hook-form"
import ModalGraph from "../modules/ModalGraph";
import axios from "axios";
import {useState} from "react";

const apiUrl = process.env.REACT_APP_API_URL;

export default function PlotDevice() {

    const { register, handleSubmit } = useForm()
    const [submittedData, setSubmittedData] = React.useState();
    const [submitted, setSubmitted] = React.useState(false);
    const [typicalData, setTypicalData] = useState("")
    const [atypicalData, setAtypicalData] = useState("")


    async function getModel(name, IDToken){

        let dataObject = {};
        dataObject.typical = [];
        dataObject.atypical = [];

        try {
            const response = await axios.get(`${apiUrl}/api/tooling/com/${name}`);
            // The response.data is already a JavaScript object, no need to parse
            const jData = response.data;
            
            // Process each data point
            jData.forEach(line => {
                const holdMe = {
                    x: line.x,
                    y: line.y
                };
                
                if (!line.atypical) {
                    dataObject.typical.push(holdMe);
                } else {
                    dataObject.atypical.push(holdMe);
                }
            });

            setTypicalData(dataObject.typical);
            setAtypicalData(dataObject.atypical);
            return dataObject;
            
        } catch (error) {
            console.error('Error fetching model data:', error);
            return dataObject;
        }
    }

    // Handle the submission of the form
    const onSubmit = (data) => {
        getModel(data.device).then(output => {
            setSubmittedData(data);
            setSubmitted(true);
        }
        );
    }





    return (
        <div>
            <VStack spacing='24px'>
                <Box marginY={30}>
                    <Center border="none" bg='white'>
                        <VStack spacing='2px'>
                            <SimpleGrid columns={2} spacing={10} paddingTop={10} width={900}>
                                <Box bg='white' borderWidth='1px' borderRadius='lg' height='320px' boxShadow='2xl' textAlign="center" overflow='hidden'>
                                    <Image src={Image2}/>
                                </Box>
                                <Box bg='white' borderWidth='1px' borderRadius='lg' minHeight='380px' boxShadow='2xl' textAlign="center">
                                    <VStack><Text as='b' fontSize='2xl' marginTop={2}>Plot Generator</Text></VStack>
                                    <Box p="0" m="4" maxW="395px" textAlign="center">Using the form below, select the commodity model you wish to use and enter the x and y values of your result.</Box>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <Stack spacing={5} align='stretch'>
                                            <Center>
                                            <Box w="300px" marginTop="7px" border="none">
                                                <Grid templateColumns='repeat(2, 3fr)' width={'300px'} gap={2}>

                                                    <GridItem colSpan={2}>
                                                        <InputGroup>
                                                            <InputLeftAddon>Name</InputLeftAddon>
                                                            <Input size='md' {...register("name", { required: true, maxLength: 20 })} />
                                                        </InputGroup>
                                                    </GridItem>

                                                    <GridItem >
                                                        <InputGroup>
                                                            <InputLeftAddon>X</InputLeftAddon>
                                                            <Input size='md' {...register("x",{ required: true, min: -9999, max: 9999 })}/>
                                                        </InputGroup>
                                                    </GridItem>

                                                    <GridItem >
                                                        <InputGroup>
                                                            <InputLeftAddon>Y</InputLeftAddon>
                                                            <Input size='md' {...register("y", { required: true, min: -9999, max: 9999 })}/>
                                                        </InputGroup>

                                                    </GridItem>
                                                    <GridItem colSpan={2}>
                                                        <InputGroup>
                                                            <InputLeftAddon>Commodity</InputLeftAddon>
                                                            <Select {...register("device")}>
                                                                <option value='BlackPepper'>Black Pepper</option>
                                                                <option value='Garlic'>Garlic</option>
                                                                <option value='Oregano'>Oregano</option>
                                                                <option value='PaprikaFlour'>Paprika Flour</option>
                                                                <option value='Sage'>Sage</option>
                                                                <option value='Turmeric'>Turmeric</option>
                                                                <option value='Turmeric2Percent'>Turmeric 2%</option>
                                                            </Select>
                                                        </InputGroup>
                                                    </GridItem>
                                                </Grid>
                                            </Box>
                                            </Center>

                                            <Center bg='white' minH="30px">
                                                <Box h='30px'>
                                                    {!submitted && <Button type={"submit"} colorScheme='blue'><CheckIcon/>&nbsp;Submit</Button>}
                                                    {typicalData.length>0 && <Button type={"submit"} colorScheme='blue'><RepeatIcon/>&nbsp;Re-Generate</Button>}
                                                    {typicalData.length>0 && <ModalGraph title="Commodity Plot" data={submittedData} is3D={false} myTypical={typicalData} myAtypical={atypicalData} type="plot"/>}
                                                </Box>
                                            </Center>

                                        </Stack>
                                    </form>
                                </Box>
                            </SimpleGrid>
                        </VStack>
                    </Center>
                </Box>
            </VStack>

        </div>
    )
}