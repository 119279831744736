import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Button,
  HStack,
  Spinner,
  Text,
  Flex,
  useToast
} from "@chakra-ui/react";
import { Download } from "lucide-react";
import { getResultsData, getDownloadUrl } from "../modules/JobApiService";

const ResultsModal = ({ isOpen, onClose, setId, setRefNo }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen && setId) {
        setLoading(true);
        try {
          const response = await getResultsData(setId);
          setData(response.data);
          setError(null);
        } catch (err) {
          setError(err.message);
          toast({
            title: "Error loading results",
            description: err.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [isOpen, setId, toast]);

  const handleDownload = async () => {
    try {
      const { url, filename } = await getDownloadUrl("results", setId);
      
      const response = await fetch(url);
      const blob = await response.blob();
      const objectUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
      }, 100);
    } catch (error) {
      toast({
        title: "Download failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="md">
          <HStack spacing={4} align="center">
            <Text>Results Details - Set {setRefNo}</Text>
            <Button
              leftIcon={<Download size={14} />}
              size="sm"
              onClick={handleDownload}
              fontSize="sm"
            >
              Download CSV
            </Button>
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {loading ? (
            <Flex justify="center" align="center" height="200px">
              <Spinner size="xl" />
            </Flex>
          ) : error ? (
            <Text color="red.500" fontSize="sm">{error}</Text>
          ) : (
            <TableContainer>
              <Table variant="simple" size="sm">
                <Thead>
                  <Tr>
                    <Th fontSize="xs">Sample</Th>
                    <Th isNumeric fontSize="xs">Authentic Score</Th>
                    <Th isNumeric fontSize="xs">Adulterated Score</Th>
                    <Th isNumeric fontSize="xs">Cutoff</Th>
                    <Th isNumeric fontSize="xs">Upper Cutoff</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.map((row, index) => (
                    <Tr key={index}>
                      <Td fontSize="xs">{row.Sample}</Td>
                      <Td isNumeric fontSize="xs">{Number(row.Authentic_Score).toFixed(4)}</Td>
                      <Td isNumeric fontSize="xs">{Number(row.Adulterated_Score).toFixed(4)}</Td>
                      <Td isNumeric fontSize="xs">{Number(row.Cutoff).toFixed(2)}</Td>
                      <Td isNumeric fontSize="xs">{Number(row.Upper_Cutoff).toFixed(2)}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ResultsModal;