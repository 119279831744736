// src/components/dashboard/DashboardLayout.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import {
  Box,
  Button,
  useDisclosure,
  VStack,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { AddIcon, RepeatIcon, EditIcon } from "@chakra-ui/icons";
import { useDashboard } from "../../modules/dashboard/DashboardContext";
import WidgetWrapper from "./widgets/WidgetWrapper";
import AddWidgetModal from "./AddWidgetModal";

const ResponsiveGridLayout = WidthProvider(Responsive);

// Define styles as a separate StyleSheet instead of using Global
const styles = `
  .widget-edit-mode .draggable-header {
    cursor: grab !important;
  }
  .widget-edit-mode .draggable-header:active {
    cursor: grabbing !important;
  }
  .widget-edit-mode .widget-header button {
    cursor: pointer !important;
  }
  .widget-edit-mode .widget-header button:hover {
    cursor: pointer !important;
  }
  .widget-edit-mode > div {
    border: 2px solid var(--chakra-colors-blue-300) !important;
    transition: border-color 0.2s ease;
  }
`;

const DashboardLayout = () => {
  const { getUserFirstName } = useAuth();
  const [firstName, setFirstName] = useState("");
  const {
    widgets,
    layouts,
    loading,
    saveDashboardConfig,
    loadDashboardConfig,
  } = useDashboard();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    const fetchFirstName = async () => {
      try {
        const name = await getUserFirstName();
        setFirstName(name || "User"); // Fallback to "User" if name is null
      } catch (error) {
        console.error("Error fetching user's first name:", error);
        setFirstName("User"); // Fallback to "User" on error
      }
    };

    fetchFirstName();
  }, []);

  // Add styles to document head
  React.useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  const handleLayoutChange = (currentLayout, allLayouts) => {
    if (isEditMode) {
      // Ensure minimum dimensions are maintained
      const fixedLayouts = {};
      Object.keys(allLayouts).forEach(breakpoint => {
        fixedLayouts[breakpoint] = allLayouts[breakpoint].map(layout => ({
          ...layout,
          w: Math.max(layout.w, 6),  // minimum width
          h: Math.max(layout.h, 4)   // minimum height
        }));
      });
      saveDashboardConfig(widgets, fixedLayouts);
    }
  };

  const handleRefresh = () => {
    loadDashboardConfig();
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <Box width="100%">
      <HStack spacing={4} mb={4} justify="space-between" p={4}>
        <Text fontSize="2xl" fontWeight="bold">
          {firstName}'s Dashboard
        </Text>
        <HStack spacing={4}>
          <Button
            leftIcon={<Icon as={RepeatIcon} />}
            onClick={handleRefresh}
            isLoading={loading}
            colorScheme="blue"
            variant="outline"
          >
            Refresh
          </Button>
          <Button
            leftIcon={<Icon as={EditIcon} />}
            onClick={toggleEditMode}
            colorScheme={isEditMode ? "blue" : "gray"}
            variant={isEditMode ? "solid" : "outline"}
          >
            {isEditMode ? "Done Editing" : "Edit Dashboard"}
          </Button>
          <Button
            leftIcon={<Icon as={AddIcon} />}
            onClick={onOpen}
            colorScheme="blue"
            isDisabled={!isEditMode}
          >
            Add Widget
          </Button>
        </HStack>
      </HStack>

      <Box
        bg="white"
        borderRadius="lg"
        boxShadow="sm"
        minHeight="calc(100vh - 200px)"
        width="100%"
        mx="auto"
        px={4}
        position="relative"
      >
        {isEditMode && (
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="repeating-linear-gradient(0deg, transparent, transparent 99px, rgba(66, 153, 225, 0.1) 100px), repeating-linear-gradient(90deg, transparent, transparent 99px, rgba(66, 153, 225, 0.1) 100px)"
            pointerEvents="none"
            zIndex={0}
          />
        )}
        <ResponsiveGridLayout
        className={`layout ${isEditMode ? 'edit-mode' : ''}`}
        layouts={layouts}
        onLayoutChange={handleLayoutChange}
        isDraggable={isEditMode && !isDragging}
        isResizable={isEditMode && !isDragging}
        draggableCancel=".widget-header button"
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 24, md: 20, sm: 12, xs: 8, xxs: 4 }}
        rowHeight={50}
        margin={[8, 8]}
        containerPadding={[8, 8]}
        preventCollision={false}
        useCSSTransforms={true}
      >
        {widgets.map((widget) => (
          <Box
            key={widget.instanceId}
            className={isEditMode ? "widget-edit-mode" : ""}
          >
            <WidgetWrapper
              widget={widget}
              setIsDragging={setIsDragging}
              isEditMode={isEditMode}
            />
          </Box>
        ))}
      </ResponsiveGridLayout>
    </Box>

      <AddWidgetModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};

export default DashboardLayout;
