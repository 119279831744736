import React from 'react';
import AuthPage from '../components/auth/AuthPage';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';

const Root = () => {
  const { user } = useAuth();
  
  // If user is authenticated, redirect to menu
  if (user) {
    return <Navigate to="/portal/dashboard" replace />;
  }

  // Otherwise show auth page
  return <AuthPage />;
};

export default Root;