import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const api = axios

export const fetchJobs = async () => {
  const response = await api.get(`${apiUrl}/api/job`);
  return response.data;
};

export const fetchSets = async (jobId) => {
  const response = await api.get(`${apiUrl}/api/sets/${jobId}`);
  return response.data;
};

export const fetchCommodities = async () => {
  const response = await api.get(`${apiUrl}/api/commodities`);
  return response.data;
};

export const fetchModels = async () => {
  const response = await api.get(`${apiUrl}/api/models`);
  return response.data;
};

export const getDownloadUrl = async (type, setId) => {
  try {
    const response = await api.get(`${apiUrl}/api/sets/download/${type}/${setId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getPlotData = async (setId) => {
  try {
    const response = await api.get(`${apiUrl}/api/sets/download/plot-data/${setId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSpectrogramData = async (setId, includeControls = false) => {
  try {
    const response = await api.get(`${apiUrl}/api/sets/download/spectrogram-data/${setId}?include_controls=${includeControls}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getResultsData = async (setId) => {
  try {
    const response = await api.get(`${apiUrl}/api/sets/results-data/${setId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const JOB_STATUS = {
  1: { label: "Created", color: "gray" },
  2: { label: "Processing", color: "blue" },
  3: { label: "Complete", color: "green" },
  4: { label: "Errored", color: "red" },
  5: { label: "Cancelled", color: "orange" },
  6: { label: "Complete with Errors", color: "yellow" },
};

export const SET_STATUS = {
  1: { label: "Created", color: "gray" },
  2: { label: "Averaging", color: "purple" },
  3: { label: "Predicting", color: "blue" },
  4: { label: "Finalising", color: "teal" },
  5: { label: "Complete", color: "green" },
  6: { label: "Errored", color: "red" },
};
