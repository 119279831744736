// src/components/dashboard/widgets/RecentJobsWidget.js
import React, { useState, useEffect } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Skeleton,
  Text,
  IconButton,
  HStack,
  useToast,
  Tooltip,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { RepeatIcon, SettingsIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { formatDateTime, getStatusColor } from '../../../modules/dashboard/utils';
import { useDashboard } from '../../../modules/dashboard/DashboardContext';

const apiUrl = process.env.REACT_APP_API_URL;

const RecentJobsWidget = ({ widget }) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();
  const { updateWidgetSettings } = useDashboard();
  
  // Get limit from widget settings with default fallback
  const limit = widget.settings?.limit || 5;

  const loadJobs = async () => {
    // Don't set loading true if we're just refreshing with the same limit
    const isInitialLoad = jobs.length === 0;
    try {
      if (isInitialLoad) {
        setLoading(true);
      }
      const response = await axios.get(`${apiUrl}/api/dashboard/recent-jobs`, {
        params: { limit }
      });
      setJobs(response.data);
      setError(null);
    } catch (err) {
      setError('Failed to load recent jobs');
      console.error(err);
      toast({
        title: "Error loading jobs",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadJobs();
  }, [limit]);

  const handleViewJob = (job) => {
    navigate(`/portal/predictions/sets`, {
      state: {
        jobId: job.id,
        jobName: job.jobname,
        commodityName: job.commodity_name,
        userName: job.userName,
        raised: job.raised,
      },
    });
  };

  const handleLimitChange = async (value) => {
    await updateWidgetSettings(widget.instanceId, { limit: value });
  };

  const renderContent = () => {
    if (error) {
      return (
        <Box textAlign="center" py={4}>
          <Text color="red.500">{error}</Text>
        </Box>
      );
    }

    if (loading) {
      return Array(limit).fill(0).map((_, i) => (
        <Tr key={i}>
          <Td><Skeleton height="20px" /></Td>
          <Td><Skeleton height="20px" /></Td>
          <Td><Skeleton height="20px" /></Td>
          <Td><Skeleton height="20px" /></Td>
        </Tr>
      ));
    }

    return jobs.map((job) => (
      <Tr
        key={job.id}
        cursor="pointer"
        _hover={{ bg: 'gray.50' }}
        onClick={() => handleViewJob(job)}
      >
        <Td>
          <Tooltip label={job.jobname} placement="top-start">
            <Text isTruncated maxW="200px">
              {job.jobname}
            </Text>
          </Tooltip>
        </Td>
        <Td>{job.commodity_name}</Td>
        <Td>
          <Badge
            colorScheme={getStatusColor(job.status_name)}
            variant="subtle"
            px={2}
            py={1}
            borderRadius="md"
          >
            {job.status_name}
          </Badge>
        </Td>
        <Td>{formatDateTime(job.raised)}</Td>
      </Tr>
    ));
  };

  return (
    <VStack align="stretch" spacing={4}>
      <HStack spacing={2} justify="space-between">
        <Text fontSize="sm" color="gray.600">
          Showing the {limit} most recent jobs
        </Text>
        <HStack spacing={2}>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<SettingsIcon />}
              variant="ghost"
              size="sm"
              aria-label="Settings"
            />
            <MenuList>
              <Box px={4} py={2}>
                <Text mb={2} fontSize="sm">Number of rows</Text>
                <NumberInput
                  size="sm"
                  min={1}
                  max={20}
                  value={limit}
                  onChange={(_, value) => handleLimitChange(value)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
            </MenuList>
          </Menu>
          <IconButton
            icon={<RepeatIcon />}
            size="sm"
            variant="ghost"
            aria-label="Refresh"
            onClick={loadJobs}
            isLoading={loading}
          />
        </HStack>
      </HStack>

      <Box overflowX="auto">
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th>Job Name</Th>
              <Th>Commodity</Th>
              <Th>Status</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {renderContent()}
          </Tbody>
        </Table>
      </Box>

      {jobs.length === 0 && !loading && !error && (
        <Box textAlign="center" py={4}>
          <Text color="gray.500">No recent jobs found</Text>
        </Box>
      )}
    </VStack>
  );
};

export default RecentJobsWidget;