import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  Text,
  VStack,
  HStack,
  Skeleton
} from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import axios from 'axios';
import { getDeviationSeverity } from '../../../modules/dashboard/utils';

const apiUrl = process.env.REACT_APP_API_URL;

const DeviationWidget = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCommodity, setSelectedCommodity] = useState('all');

  const loadData = async () => {
    try {
      setLoading(true);
      const params = selectedCommodity !== 'all' ? { commodity_id: selectedCommodity } : {};
      const response = await axios.get(`${apiUrl}/api/dashboard/deviation-analysis`, { params });
      setData(response.data);
    } catch (err) {
      setError('Failed to load deviation analysis');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [selectedCommodity]);

  if (loading) {
    return <Skeleton height="200px" />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  const plotData = [{
    x: data.map(d => d.date),
    y: data.map(d => d.deviation),
    type: 'scatter',
    mode: 'lines',
    name: 'Deviation',
    line: {
      color: 'rgb(75, 192, 192)',
      width: 2
    },
    hovertemplate: '%{y:.2f}%<extra></extra>'
  }];

  const layout = {
    title: {
      text: 'Deviation Analysis',
      x: 0.5,
      xanchor: 'center',
      font: {
        size: 16
      }
    },
    autosize: true,
    height: 200,
    margin: {
      l: 50,
      r: 20,
      t: 40,
      b: 40
    },
    xaxis: {
      title: 'Date',
      showgrid: true,
      gridcolor: '#E2E8F0'
    },
    yaxis: {
      title: 'Deviation (%)',
      ticksuffix: '%',
      showgrid: true,
      gridcolor: '#E2E8F0',
      zeroline: true,
      zerolinecolor: '#CBD5E0'
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    showlegend: false,
    hovermode: 'x unified'
  };

  const config = {
    displayModeBar: false,
    responsive: true
  };

  return (
    <VStack spacing={4} align="stretch">
      <Select
        value={selectedCommodity}
        onChange={(e) => setSelectedCommodity(e.target.value)}
      >
        <option value="all">All Commodities</option>
        {/* Add commodity options dynamically */}
      </Select>
      
      <Box>
        <Plot
          data={plotData}
          layout={layout}
          config={config}
          style={{ width: '100%' }}
        />
      </Box>

      <Box>
        <Text fontWeight="bold" mb={2}>Recent Significant Deviations:</Text>
        <VStack align="stretch" spacing={2}>
          {data.slice(0, 5).map((item) => (
            <HStack 
              key={item.set_id} 
              justify="space-between" 
              p={2} 
              bg="gray.50" 
              borderRadius="md"
            >
              <Text>{item.commodity}</Text>
              <Text
                color={Math.abs(item.deviation) > 25 ? 'red.500' : 'orange.500'}
                fontWeight="bold"
              >
                {item.deviation.toFixed(2)}%
              </Text>
            </HStack>
          ))}
        </VStack>
      </Box>
    </VStack>
  );
};

export default DeviationWidget;