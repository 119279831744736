import React from "react";
import { Center } from "@chakra-ui/react";
import { Box, VStack } from "@chakra-ui/react";
import { SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Image1 from "../assets/Image1-250.jpg";
import Image2 from "../assets/Image2-250.jpg";
import Image3 from "../assets/Image3-250.jpg";
import Image4 from "../assets/Image4-250.png";
import ImagePredictions from "../assets/predictions.png";
import MenuCard from "../components/MenuCard";

export default function MainMenu() {
  const navigate = useNavigate();

  const cards = [
    {
      image: ImagePredictions,
      title: "Predictions",
      description:
        "Perform predictions with csv/tsv files generated from a device",
      buttonText: "Predictions",
      onClick: () => navigate("/portal/predictions"),
    },
    {
      image: Image1,
      title: "Averaged Spectra",
      description:
        "Our powerful cloud functions will process multiple SPA files, for multiple samples, and average the results per sample.",
      buttonText: "Averaged Spectra",
      onClick: () => navigate("/portal/average"),
    },
    {
      image: Image2,
      title: "Generate a 2D chart",
      description:
        "You can generate a plot based on provided sample data. Once you have your data you can generate a wavelength chart using the following button.",
      buttonText: "2D Plot Generator",
      onClick: () => navigate("/portal/plot"),
    },
    {
      image: Image3,
      title: "Generate a 3D chart",
      description:
        "You can generate a interactive 3D graphic based on provided sample data. Once you have your data you can generate a wavelength chart using the following button.",
      buttonText: "3D Plot Generator",
      onClick: () => navigate("/portal/plot3D"),
    },
    {
      image: Image4,
      title: "My Activity History",
      description:
        "Review your previous activities and run them again. Highly useful for Averaged Spectra analysis and file downloads.",
      buttonText: "My Activity History",
      onClick: () => navigate("/portal/history"),
    },
  ];

  return (
    <VStack spacing="24px">
      <Box marginY={10} width="full">
        <Center>
          <SimpleGrid
            columns={3}
            spacing={10}
            paddingTop={10}
            width="1000px"
            maxWidth="100%"
          >
            {cards.map((card, index) => (
              <MenuCard key={index} {...card} />
            ))}
          </SimpleGrid>
        </Center>
      </Box>
    </VStack>
  );
}
