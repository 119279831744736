import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";

const ModelsTable = ({ models, commodities, fetchModels, apiUrl }) => {
  const [newModelFileId, setNewModelFile] = useState(null);
  const [newModelName, setNewModelName] = useState(null);
  const [deleteModelFileId, setDeleteModelFile] = useState(null);
  const toast = useToast();

  const isRdsFile = (value) => value && value.name.endsWith(".rds");
const hasUniqueName = (value) => !models.some(
  (c) => c.filename.toLowerCase() === value.name?.toLowerCase()
);

  // Add New Model File Form
  const uploadFormik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: Yup.object({
        file: Yup.mixed()
        .required("Please select a file")
        .test("fileType", "Only .rds files are allowed", isRdsFile)
        .test("unique-name", "This filename is already in use", hasUniqueName),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        // Get presigned URL
        const response = await axios.post(
          `${apiUrl}/api/models/upload`,
          {
            filename: values.file.name,
          }
        );

        const s3Request = axios.create();
delete s3Request.defaults.headers.common['Authorization'];

        // Upload file to S3
        await s3Request.put(response.data.url, values.file, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });

        await axios.post(
          `${apiUrl}/api/models/update`,
          {
            id: newModelFileId,
            filename: values.file.name,
          }
        );

        toast({
          title: "Success",
          description: "Model file uploaded successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        fetchModels();
        setNewModelFile(null);
      } catch (error) {
        toast({
          title: "Error",
          description:
            error.response?.data?.message || "Failed to upload model file",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setSubmitting(false);
    },
  });

  // Delete Model Form
  const deleteFormik = useFormik({
    initialValues: {
      confirmDelete: "",
    },
    validationSchema: Yup.object({
      confirmDelete: Yup.string()
        .required('Please type "delete" to confirm')
        .matches(/^delete$/, 'Please type "delete" to confirm'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const isModelAssigned = commodities.some(
        (commodity) => commodity.modelId === deleteModelFileId
      );

      if (isModelAssigned) {
        toast({
          title: "Error",
          description: "Cannot delete model as it is assigned to commodities",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      try {
        await axios.delete(`${apiUrl}/api/models/${deleteModelFileId}`);

        toast({
          title: "Success",
          description: "Model deleted successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        fetchModels();
        setDeleteModelFile(null);
      } catch (error) {
        toast({
          title: "Error",
          description:
            error.response?.data?.message || "Failed to delete model",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setSubmitting(false);
    },
  });

  return (
    <>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Type</Th>
            <Th>Preprocessing</Th>
            <Th>Cutoffs (L/M/U)</Th>
            <Th>Plotting Enabled</Th>
            <Th>Filename</Th>
            {/* <Th>Actions</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {models.map((model) => (
            <Tr key={model.id}>
              <Td>{model.name}</Td>
              <Td>{model.modeltype}</Td>
              <Td>{model.preprocessing}</Td>
              <Td>{`${model.lower}/${model.middle}/${model.upper}`}</Td>
              <Td>{model.enablePlotting ? "True" : "False"}</Td>
              <Td>{model.filename}</Td>
              {/* <Td>
                <Menu>
                  <MenuButton
                    as={IconButton}
                    icon={<ChevronDownIcon />}
                    variant="outline"
                  />
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setNewModelName(model.name);
                        setNewModelFile(model.id);
                      }}
                    >
                      Add New Model File
                    </MenuItem>
                    <MenuItem onClick={() => setDeleteModelFile(model.id)}>
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Td> */}
            </Tr>
          ))}
        </Tbody>
      </Table>

      {/* Add New Model File Modal */}
      <Modal
        isOpen={newModelFileId !== null}
        onClose={() => setNewModelFile(null)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Model File - {newModelName}</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={uploadFormik.handleSubmit}>
            <ModalBody>
              <FormControl
                isInvalid={
                  uploadFormik.touched.file && uploadFormik.errors.file
                }
              >
                <FormLabel>Select new RDS file for {newModelName}</FormLabel>
                <Input
                  type="file"
                  accept=".rds"
                  onChange={(event) => {
                    uploadFormik.setFieldValue(
                      "file",
                      event.currentTarget.files[0]
                    );
                  }}
                />
                <FormErrorMessage>{uploadFormik.errors.file}</FormErrorMessage>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={() => setNewModelFile(null)}>
                Cancel
              </Button>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={uploadFormik.isSubmitting}
              >
                Upload
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      {/* Delete Model Modal */}
      <Modal
        isOpen={deleteModelFileId !== null}
        onClose={() => setDeleteModelFile(null)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Model</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={deleteFormik.handleSubmit}>
            <ModalBody>
              {commodities.some((c) => c.modelId === deleteModelFileId) ? (
                <div>
                  This model cannot be deleted as it is assigned to commodities.
                </div>
              ) : (
                <FormControl
                  isInvalid={
                    deleteFormik.touched.confirmDelete &&
                    deleteFormik.errors.confirmDelete
                  }
                >
                  <FormLabel>Type "delete" to confirm deletion</FormLabel>
                  <Input
                    name="confirmDelete"
                    onChange={deleteFormik.handleChange}
                    value={deleteFormik.values.confirmDelete}
                    placeholder="delete"
                  />
                  <FormErrorMessage>
                    {deleteFormik.errors.confirmDelete}
                  </FormErrorMessage>
                </FormControl>
              )}
            </ModalBody>
            <ModalFooter>
              <Button mr={3} onClick={() => setDeleteModelFile(null)}>
                Cancel
              </Button>
              <Button
                colorScheme="red"
                type="submit"
                isLoading={deleteFormik.isSubmitting}
                isDisabled={commodities.some(
                  (c) => c.modelId === deleteModelFileId
                )}
              >
                Delete
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModelsTable;
