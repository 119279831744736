import React from "react";
import { VStack, Box, Text, Button, Image } from "@chakra-ui/react";

export default function Card({image, title, description, buttonText, onClick}) {
  return (
    <Box
      bg="white"
      borderWidth="1px"
      borderRadius="lg"
      height="400px"
      boxShadow="2xl"
      display="flex"
      flexDirection="column"
    >
      {/* Image container with fixed height */}
      <Box height="200px" position="relative">
        <Image
          src={image}
          alt={title}
          objectFit="cover"
          width="100%"
          height="100%"
          borderBottom="1px"
        />
      </Box>

      {/* Content container with flex layout */}
      <VStack
        flex="1"
        spacing="3"
        padding="4"
        justify="space-between"
        align="center"
      >
        {/* Text content */}
        <VStack spacing="2" flex="1">
          <Text as="b" fontSize="2xl" noOfLines={1}>
            {title}
          </Text>
          <Text noOfLines={3}>{description}</Text>
        </VStack>

        {/* Button always at bottom */}
        <Button
          bg="slategray"
          color="white"
          size="md"
          width="full"
          onClick={onClick}
        >
          {buttonText}
        </Button>
      </VStack>
    </Box>
  );
}
