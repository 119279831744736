// src/components/dashboard/AddWidgetModal.js
import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  Box,
  Text,
  Button,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useDashboard } from '../../modules/dashboard/DashboardContext';

const AddWidgetModal = ({ isOpen, onClose }) => {
  const { availableWidgets, addWidget } = useDashboard();
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  const handleAddWidget = (widgetId) => {
    addWidget(widgetId);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Widget</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {availableWidgets.length === 0 ? (
            <VStack spacing={4} py={8} textAlign="center">
              <Text color="gray.500">
                All available widgets are already on your dashboard.
              </Text>
            </VStack>
          ) : (
            <SimpleGrid columns={2} spacing={4}>
              {availableWidgets.map((widget) => (
                <Box
                  key={widget.id}
                  p={4}
                  border="1px solid"
                  borderColor={borderColor}
                  borderRadius="md"
                  cursor="pointer"
                  _hover={{ bg: hoverBg }}
                >
                  <Text fontWeight="bold" mb={2}>
                    {widget.title}
                  </Text>
                  <Text fontSize="sm" color="gray.600" mb={4}>
                    {widget.description}
                  </Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    w="full"
                    onClick={() => handleAddWidget(widget.id)}
                  >
                    Add Widget
                  </Button>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddWidgetModal;