import React, { useState } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  IconButton,
  Flex,
  Select,
  Button,
  Text,
  Stack,
} from '@chakra-ui/react';
import { ViewIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const PaginationControls = ({ 
  currentPage, 
  pageSize, 
  totalItems, 
  onPageChange, 
  onPageSizeChange 
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);
  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  return (
    <Flex justify="space-between" align="center" w="full" gap={4}>
      <Stack direction="row" spacing={2} align="center">
        <Text>Show</Text>
        <Select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          w="100px"
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </Select>
        <Text>entries</Text>
      </Stack>

      <Stack direction="row" spacing={2} align="center">
        <Text>
          Showing {startItem} to {endItem} of {totalItems} entries
        </Text>
        <Button
          size="sm"
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={currentPage === 1}
          leftIcon={<ChevronLeftIcon />}
        >
          Previous
        </Button>
        <Text>
          Page {currentPage} of {totalPages}
        </Text>
        <Button
          size="sm"
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={currentPage === totalPages}
          rightIcon={<ChevronRightIcon />}
        >
          Next
        </Button>
      </Stack>
    </Flex>
  );
};

const JobsTable = ({ jobs, commodities, JOB_STATUS, handleViewSets }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  // Calculate pagination
  const totalItems = jobs.length;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentJobs = jobs.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setCurrentPage(1); // Reset to first page when changing page size
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Box p={4}>
        <PaginationControls
          currentPage={currentPage}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th isNumeric>ID</Th>
              <Th>Name</Th>
              <Th>Date</Th>
              <Th>Commodity</Th>
              <Th>Status</Th>
              <Th>User</Th>
              <Th>Details</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentJobs.map((job) => (
              <Tr key={job.jobId}>
                <Td isNumeric>{job.jobId}</Td>
                <Td>{job.jobName}</Td>
                <Td>{new Date(job.raised).toLocaleString()}</Td>
                <Td>{commodities[job.commodityId]?.name || 'Unknown'}</Td>
                <Td>
                  <Badge
                    colorScheme={JOB_STATUS[job.jobStatusId]?.color || 'gray'}
                    p={1}
                    borderRadius="md"
                  >
                    {JOB_STATUS[job.jobStatusId]?.label || 'Unknown'}
                  </Badge>
                </Td>
                <Td>{job.userName}</Td>
                <Td>
                  <IconButton
                    aria-label="View sets"
                    icon={<ViewIcon />}
                    size="sm"
                    onClick={() => handleViewSets(job)}
                    colorScheme="blue"
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Box p={4}>
        <PaginationControls
          currentPage={currentPage}
          pageSize={pageSize}
          totalItems={totalItems}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
      </Box>
    </Box>
  );
};

export default JobsTable;