import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Text,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import {
  fetchJobs,
  fetchCommodities,
  fetchModels,
  JOB_STATUS,
} from "../modules/JobApiService";
import JobsTable from "./JobsTable.jsx";

const JobsPage = () => {
  const [jobs, setJobs] = useState([]);
  const [commodities, setCommodities] = useState({});
  const [models, setModels] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  const loadData = async () => {
    try {
      const [jobsData, commoditiesData, modelsData] = await Promise.all([
        fetchJobs(),
        fetchCommodities(),
        fetchModels(),
      ]);

      // Convert commodities and models arrays to lookup objects
      const commoditiesMap = commoditiesData.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      const modelsMap = modelsData.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      setJobs(jobsData);
      setCommodities(commoditiesMap);
      setModels(modelsMap);
      setError(null);
    } catch (err) {
      setError("Failed to load data");
      toast({
        title: "Error loading data",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
    // Set up auto-refresh
    const intervalId = setInterval(loadData, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const handleViewSets = (job) => {
    navigate(`/portal/predictions/sets`, {
      state: {
        jobId: job.jobId,
        jobName: job.jobName,
        commodityName: job.commodityName,
        userName: job.userName,
        raised: job.raised,
        customerRef: job.customerRef,
      },
    });
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="300px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" height="300px">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  return (
    <JobsTable
      jobs={jobs}
      commodities={commodities}
      JOB_STATUS={JOB_STATUS}
      handleViewSets={handleViewSets}
    />
  );
};

export default JobsPage;