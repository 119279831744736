// src/modules/dashboard/utils.js
import { format, subDays, parseISO } from 'date-fns';

export const parseScore = (scoreString) => {
  try {
    const score = JSON.parse(scoreString);
    return {
      cutoff: score.cutoff,
      upperCutoff: score.upper_cutoff,
      authenticScore: score.authentic_score,
      isTypical: score.authentic_score >= score.cutoff && 
                 score.authentic_score <= score.upper_cutoff,
      deviation: calculateDeviation(
        score.authentic_score,
        score.cutoff,
        score.upper_cutoff
      )
    };
  } catch (error) {
    console.error('Error parsing score:', error);
    return null;
  }
};

export const calculateDeviation = (score, lowerCutoff, upperCutoff) => {
  if (score >= lowerCutoff && score <= upperCutoff) {
    return 0;
  }
  
  // Calculate how far outside the range the score is
  if (score < lowerCutoff) {
    return ((score - lowerCutoff) / lowerCutoff) * 100;
  }
  return ((score - upperCutoff) / upperCutoff) * 100;
};

export const getTimeRangeFilter = (timeRange) => {
  const now = new Date();
  switch (timeRange) {
    case '7d':
      return subDays(now, 7);
    case '30d':
      return subDays(now, 30);
    case '90d':
      return subDays(now, 90);
    default:
      return subDays(now, 30);
  }
};

export const formatDateTime = (dateString) => {
  try {
    return format(parseISO(dateString), 'MMM d, yyyy HH:mm');
  } catch {
    return dateString;
  }
};

export const getStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'completed':
      return 'green.500';
    case 'in_progress':
      return 'blue.500';
    case 'failed':
      return 'red.500';
    case 'waiting':
      return 'orange.500';
    default:
      return 'gray.500';
  }
};

export const getDeviationSeverity = (deviation) => {
  const absDeviation = Math.abs(deviation);
  if (absDeviation === 0) return 'typical';
  if (absDeviation <= 10) return 'slight';
  if (absDeviation <= 25) return 'moderate';
  return 'severe';
};