import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  Textarea,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Badge,
  IconButton,
  Tooltip,
  useClipboard,
  Code,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { CopyIcon, CheckIcon, RepeatIcon } from '@chakra-ui/icons';

const DevTools = () => {
  const { getIdToken, getCurrentSession, user } = useAuth();
  const [token, setToken] = useState('');
  const [decodedToken, setDecodedToken] = useState(null);
  const [session, setSession] = useState(null);
  const toast = useToast();
  const { hasCopied, onCopy } = useClipboard(token);

  // Function to decode JWT token
  const decodeJWT = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const handleShowToken = async () => {
    try {
      const idToken = await getIdToken();
      setToken(idToken);
      const decoded = decodeJWT(idToken);
      setDecodedToken(decoded);
    } catch (error) {
      toast({
        title: 'Error fetching token',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleShowSession = async () => {
    try {
      const currentSession = await getCurrentSession();
      setSession(currentSession);
    } catch (error) {
      toast({
        title: 'Error fetching session',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCopyToken = () => {
    onCopy();
    toast({
      title: 'Token copied',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  // Helper function to render object as table rows
  const renderObjectAsRows = (obj, parentKey = '') => {
    return Object.entries(obj).map(([key, value]) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      if (typeof value === 'object' && value !== null) {
        return renderObjectAsRows(value, fullKey);
      }
      return (
        <Tr key={fullKey}>
          <Td fontWeight="medium">{fullKey}</Td>
          <Td fontFamily="mono">
            {Array.isArray(value) ? value.join(', ') : String(value)}
          </Td>
        </Tr>
      );
    });
  };

  return (
    <Container maxW="container.lg" py={8}>
      <VStack spacing={6} align="stretch">
        <Heading size="lg">Development Tools</Heading>
        
        {/* Current User Info */}
        <Card>
          <CardHeader>
            <Heading size="md">Current User</Heading>
          </CardHeader>
          <CardBody>
            <VStack align="stretch" spacing={3}>
              <HStack>
                <Text fontWeight="bold">Username:</Text>
                <Text>{user?.username}</Text>
              </HStack>
              <HStack>
                <Text fontWeight="bold">User ID:</Text>
                <Text>{user?.userId}</Text>
              </HStack>
            </VStack>
          </CardBody>
        </Card>

        {/* Token Management and Decoded Information */}
        <Card>
          <CardHeader>
            <HStack justify="space-between">
              <Heading size="md">Token Information</Heading>
              <Badge colorScheme={token ? 'green' : 'gray'}>
                {token ? 'Token Available' : 'No Token'}
              </Badge>
            </HStack>
          </CardHeader>
          <CardBody>
            <VStack spacing={4} align="stretch">
              <HStack>
                <Button
                  leftIcon={<RepeatIcon />}
                  colorScheme="blue"
                  onClick={handleShowToken}
                >
                  Fetch Token
                </Button>
                {token && (
                  <Tooltip label={hasCopied ? 'Copied!' : 'Copy token'}>
                    <IconButton
                      icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
                      onClick={handleCopyToken}
                      colorScheme={hasCopied ? 'green' : 'gray'}
                    />
                  </Tooltip>
                )}
              </HStack>

              {token && (
                <Accordion allowMultiple defaultIndex={[1]}>
                  {/* Raw Token */}
                  <AccordionItem>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Raw Token
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      <Textarea
                        value={token}
                        isReadOnly
                        size="sm"
                        rows={4}
                        fontFamily="mono"
                      />
                    </AccordionPanel>
                  </AccordionItem>

                  {/* Decoded Token Information */}
                  <AccordionItem>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Decoded Token Information
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                      {decodedToken ? (
                        <Box overflowX="auto">
                          <Table variant="simple" size="sm">
                            <Thead>
                              <Tr>
                                <Th>Claim</Th>
                                <Th>Value</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {renderObjectAsRows(decodedToken)}
                            </Tbody>
                          </Table>
                        </Box>
                      ) : (
                        <Text color="gray.500">No token decoded yet</Text>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              )}
            </VStack>
          </CardBody>
        </Card>

        {/* Session Info */}
        <Card>
          <CardHeader>
            <HStack justify="space-between">
              <Heading size="md">Session Information</Heading>
              <Button size="sm" onClick={handleShowSession}>
                Fetch Session
              </Button>
            </HStack>
          </CardHeader>
          <CardBody>
            {session ? (
              <Box>
                <Code p={4} borderRadius="md" width="100%" display="block" whiteSpace="pre-wrap">
                  {JSON.stringify(session, null, 2)}
                </Code>
              </Box>
            ) : (
              <Text color="gray.500">Click 'Fetch Session' to view session details</Text>
            )}
          </CardBody>
        </Card>

        {/* Postman Instructions */}
        <Card>
          <CardHeader>
            <Heading size="md">Postman Instructions</Heading>
          </CardHeader>
          <CardBody>
            <VStack align="stretch" spacing={3}>
              <Text>To use the ID token in Postman:</Text>
              <Box bg="gray.50" p={4} borderRadius="md">
                <VStack align="stretch" spacing={2}>
                  <Text>1. Copy the token using the copy button above</Text>
                  <Text>2. In Postman, go to the Authorization tab</Text>
                  <Text>3. Select "Bearer Token" from the Type dropdown</Text>
                  <Text>4. Paste the token into the Token field</Text>
                </VStack>
              </Box>
            </VStack>
          </CardBody>
        </Card>
      </VStack>
    </Container>
  );
};

export default DevTools;