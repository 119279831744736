import React, { useState } from 'react';
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';

// spiceMarkers data remains the same
const spiceMarkers = {
    Basil: [
      { component: "Eugenol", wavenumber: 1514 },
      { component: "Linalool", wavenumber: 1640 },
      { component: "Estragole", wavenumber: 1609 },
      { component: "Methylchavicol", wavenumber: 1450 }
    ],
    "Black Pepper": [
      { component: "Piperine", wavenumber: 1633 },
      { component: "Beta-caryophyllene", wavenumber: 1600 },
      { component: "Limonene", wavenumber: 1644 }
    ],
    Chilli: [
      { component: "Capsaicin", wavenumber: 1628 },
      { component: "Dihydrocapsaicin", wavenumber: 1515 },
      { component: "Capsanthin", wavenumber: 1160 }
    ],
    Cinnamon: [
      { component: "Cinnamaldehyde", wavenumber: 1677 },
      { component: "Eugenol", wavenumber: 1514 },
      { component: "Coumarin", wavenumber: 1720 }
    ],
    "Common Wheat Flour": [
      { component: "Starch", wavenumber: 1022 },
      { component: "Protein (Gluten)", wavenumber: 1650 },
      { component: "Lipids", wavenumber: 1745 }
    ],
    Coriander: [
      { component: "Linalool", wavenumber: 1640 },
      { component: "Geranyl acetate", wavenumber: 1737 },
      { component: "γ-terpinene", wavenumber: 888 }
    ],
    "Coriander Leaf": [
      { component: "2-decenal", wavenumber: 1690 },
      { component: "Decanal", wavenumber: 1725 },
      { component: "Essential oils", wavenumber: 1640 }
    ],
    Cumin: [
      { component: "Cuminaldehyde", wavenumber: 1685 },
      { component: "p-cymene", wavenumber: 1515 },
      { component: "β-pinene", wavenumber: 888 }
    ],
    Fennel: [
      { component: "Anethole", wavenumber: 1609 },
      { component: "Fenchone", wavenumber: 1745 },
      { component: "Estragole", wavenumber: 1515 }
    ],
    Garlic: [
      { component: "Allicin", wavenumber: 1030 },
      { component: "Diallyl disulfide", wavenumber: 1420 },
      { component: "S-allyl cysteine", wavenumber: 1515 }
    ],
    Ginger: [
      { component: "Gingerol", wavenumber: 1515 },
      { component: "Shogaol", wavenumber: 1670 },
      { component: "Zingiberene", wavenumber: 1640 }
    ],
    Mint: [
      { component: "Menthol", wavenumber: 1025 },
      { component: "Menthone", wavenumber: 1710 },
      { component: "Pulegone", wavenumber: 1678 }
    ],
    Oregano: [
      { component: "Carvacrol", wavenumber: 1585 },
      { component: "Thymol", wavenumber: 1620 },
      { component: "p-cymene", wavenumber: 1515 }
    ],
    "Paprika Flours": [
      { component: "Capsanthin", wavenumber: 1160 },
      { component: "Zeaxanthin", wavenumber: 1525 },
      { component: "β-carotene", wavenumber: 1515 }
    ],
    Parsley: [
      { component: "Myristicin", wavenumber: 1630 },
      { component: "Apiol", wavenumber: 1514 },
      { component: "Essential oils", wavenumber: 1640 }
    ],
    Rosemary: [
      { component: "Cineole", wavenumber: 1030 },
      { component: "Camphor", wavenumber: 1735 },
      { component: "Borneol", wavenumber: 1155 },
      { component: "Verbenone", wavenumber: 1680 }
    ],
    Saffron: [
      { component: "Crocin", wavenumber: 1515 },
      { component: "Picrocrocin", wavenumber: 1690 },
      { component: "Safranal", wavenumber: 1670 }
    ],
    Sage: [
      { component: "Alpha-thujone", wavenumber: 1710 },
      { component: "Beta-thujone", wavenumber: 1685 },
      { component: "Camphor", wavenumber: 1735 }
    ],
    Tarragon: [
      { component: "Estragole", wavenumber: 1515 },
      { component: "Ocimene", wavenumber: 1640 },
      { component: "Anethole", wavenumber: 1609 }
    ],
    Thyme: [
      { component: "Thymol", wavenumber: 1620 },
      { component: "Carvacrol", wavenumber: 1585 },
      { component: "p-cymene", wavenumber: 1515 }
    ],
    Turmeric: [
      { component: "Curcumin", wavenumber: 1628 },
      { component: "Demethoxycurcumin", wavenumber: 1601 },
      { component: "Turmerone", wavenumber: 1715 }
    ],
    White_pepper: [
      { component: "Piperine", wavenumber: 1633 },
      { component: "Beta-caryophyllene", wavenumber: 1600 },
      { component: "Limonene", wavenumber: 1644 }
    ]
  };

const qualityMarkers = {
  protein: { range: [1600, 1700], description: "Amide I band" },
  carbohydrates: { range: [1000, 1200], description: "C-O stretching" },
  lipids: { range: [1720, 1750], description: "C=O stretching" },
  phenolics: { range: [1500, 1600], description: "Aromatic ring stretching" },
  moisture: { range: [3200, 3400], description: "O-H stretching" }
};

const SpiceAnalysis = () => {
  const [selectedSpice, setSelectedSpice] = useState('');
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Card 
      maxW="4xl" 
      w="full" 
      bg={bgColor} 
      boxShadow="md" 
      borderRadius="lg"
    >
      <CardBody>
        <VStack spacing={6} align="stretch">
          <Select
            placeholder="Select a spice..."
            value={selectedSpice}
            onChange={(e) => setSelectedSpice(e.target.value)}
          >
            {Object.keys(spiceMarkers).sort().map(spice => (
              <option key={spice} value={spice}>{spice}</option>
            ))}
          </Select>

          {selectedSpice && (
            <VStack spacing={6} align="stretch">
              <Box 
                borderWidth="1px" 
                borderColor={borderColor} 
                borderRadius="md" 
                p={4}
              >
                <Heading size="md" mb={4}>
                  Characteristic Markers for {selectedSpice}
                </Heading>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Component</Th>
                      <Th>Wavenumber (cm⁻¹)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {spiceMarkers[selectedSpice].map((marker, index) => (
                      <Tr key={index}>
                        <Td>{marker.component}</Td>
                        <Td>{marker.wavenumber}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>

              <Box 
                borderWidth="1px" 
                borderColor={borderColor} 
                borderRadius="md" 
                p={4}
              >
                <Heading size="md" mb={4}>
                  Quality Analysis Regions
                </Heading>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Component</Th>
                      <Th>Range (cm⁻¹)</Th>
                      <Th>Description</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.entries(qualityMarkers).map(([key, value], index) => (
                      <Tr key={index}>
                        <Td textTransform="capitalize">{key}</Td>
                        <Td>{value.range[0]} - {value.range[1]}</Td>
                        <Td>{value.description}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </VStack>
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

export default SpiceAnalysis;