import React, { useState, useMemo, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  VStack,
  HStack,
  Text,
  Badge,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Alert,
  AlertIcon,
  AlertDescription,
  Input,
  Select,
  Collapse,
  useColorModeValue,
  IconButton,
  useToast,
  Checkbox,
  Divider,
  Spinner
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DownloadIcon,
  InfoIcon,
} from '@chakra-ui/icons';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  ZAxis,
  Tooltip as RechartsTooltip,
  Legend,
  ComposedChart,
  ReferenceArea,
} from 'recharts';

const apiUrl = process.env.REACT_APP_API_URL;

// SpectralPeakVisualization Component
const SpectralPeakVisualization = ({ peakData, spectralMarkers }) => {
  const [showMarkers, setShowMarkers] = useState(true);
  const [highlightRegion, setHighlightRegion] = useState(null);
  const [colorMode, setColorMode] = useState('intensity');

  const chartData = peakData.map(peak => ({
    wavenumber: peak.wavenumber,
    intensity: peak.intensity,
    prominence: peak.prominence,
    width: peak.width_50
  }));

  const getPointColor = (value) => {
    const normalizedValue = Math.min(Math.max((value + 1) / 2, 0), 1);
    const hue = 240 * (1 - normalizedValue);
    return `hsl(${hue}, 70%, 50%)`;
  };

  const cardBg = useColorModeValue('white', 'gray.700');

  return (
    <Card w="full" bg={cardBg}>
      <CardHeader>
        <HStack justify="space-between">
          <Heading size="md">Spectral Visualization</Heading>
          <HStack spacing={4}>
            <Select
              size="sm"
              value={colorMode}
              onChange={(e) => setColorMode(e.target.value)}
              w="150px"
            >
              <option value="intensity">Color by Intensity</option>
              <option value="prominence">Color by Prominence</option>
            </Select>
            <Checkbox
              isChecked={showMarkers}
              onChange={(e) => setShowMarkers(e.target.checked)}
            >
              Show Markers
            </Checkbox>
          </HStack>
        </HStack>
      </CardHeader>
      <CardBody>
        <VStack spacing={6} align="stretch">
          {/* Combined Spectrum Plot */}
          <Box h="400px">
            <ResponsiveContainer width="100%" height="100%">
              <ComposedChart
                data={chartData}
                margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="wavenumber"
                  reversed
                  label={{ value: 'Wavenumber (cm⁻¹)', position: 'bottom' }}
                />
                <YAxis
                  label={{ 
                    value: 'Intensity (a.u.)', 
                    angle: -90, 
                    position: 'left' 
                  }}
                />
                <RechartsTooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <Box 
                          bg="white" 
                          p={2} 
                          border="1px" 
                          borderColor="gray.200" 
                          borderRadius="md"
                        >
                          <Text>Wavenumber: {payload[0].payload.wavenumber.toFixed(2)}</Text>
                          <Text>Intensity: {payload[0].payload.intensity.toFixed(3)}</Text>
                          <Text>Prominence: {payload[0].payload.prominence.toFixed(3)}</Text>
                          <Text>Width: {payload[0].payload.width.toFixed(3)}</Text>
                        </Box>
                      );
                    }
                    return null;
                  }}
                />
                <Legend />
                
                <Line
                  type="monotone"
                  dataKey="intensity"
                  stroke="#8884d8"
                  dot={false}
                  name="Spectrum"
                />
                
                <Scatter
                  data={chartData}
                  fill={(entry) => getPointColor(
                    colorMode === 'intensity' ? entry.intensity : entry.prominence
                  )}
                  name="Peaks"
                />

                {showMarkers && spectralMarkers.map((marker, idx) => (
                  <ReferenceArea
                    key={idx}
                    x1={marker.region[0]}
                    x2={marker.region[1]}
                    fill={marker.status === 'normal' ? '#82ca9d' : 
                          marker.status === 'high' ? '#ffc658' : '#ff8042'}
                    fillOpacity={0.2}
                    onMouseEnter={() => setHighlightRegion(idx)}
                    onMouseLeave={() => setHighlightRegion(null)}
                  />
                ))}
              </ComposedChart>
            </ResponsiveContainer>
          </Box>

          {/* Peak Width Distribution */}
          <Box h="200px">
            <Heading size="sm" mb={4}>Peak Width Distribution</Heading>
            <ResponsiveContainer width="100%" height="100%">
              <ScatterChart
                margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="wavenumber"
                  reversed
                  label={{ value: 'Wavenumber (cm⁻¹)', position: 'bottom' }}
                />
                <YAxis
                  dataKey="width"
                  label={{ 
                    value: 'Peak Width (cm⁻¹)', 
                    angle: -90, 
                    position: 'left' 
                  }}
                />
                <ZAxis
                  dataKey="intensity"
                  range={[50, 400]}
                  name="intensity"
                />
                <RechartsTooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <Box 
                          bg="white" 
                          p={2} 
                          border="1px" 
                          borderColor="gray.200" 
                          borderRadius="md"
                        >
                          <Text>Wavenumber: {payload[0].payload.wavenumber.toFixed(2)}</Text>
                          <Text>Width: {payload[0].payload.width.toFixed(3)}</Text>
                          <Text>Intensity: {payload[0].payload.intensity.toFixed(3)}</Text>
                        </Box>
                      );
                    }
                    return null;
                  }}
                />
                <Scatter
                  data={chartData}
                  fill={(entry) => getPointColor(entry[colorMode])}
                />
              </ScatterChart>
            </ResponsiveContainer>
          </Box>
        </VStack>
      </CardBody>
    </Card>
  );
};

// Main SpectralAnalysis Component
const SpectralAnalysisTab = ({ setId }) => {
    const [analysisData, setAnalysisData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showFullReport, setShowFullReport] = useState(false);
    const [expandedAdulterants, setExpandedAdulterants] = useState([]);
    const [showAllPeaks, setShowAllPeaks] = useState(false);
    const [peakFilter, setPeakFilter] = useState('');
    const [peakSortField, setPeakSortField] = useState('wavenumber');
    const [peakSortDirection, setPeakSortDirection] = useState('asc');
    const [showVisualization, setShowVisualization] = useState(false);
    
    const toast = useToast();
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('gray.200', 'gray.600');
  
    // Fetch data from API
    useEffect(() => {
      const fetchAnalysis = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${apiUrl}/api/spectral-analysis/${setId}`);
          console.log("API Response:", response.data); // Debug log
          setAnalysisData(response.data);
          setError(null);
        } catch (err) {
          console.error("Error fetching data:", err);
          setError(err.response?.data?.detail || err.message);
        } finally {
          setLoading(false);
        }
      };
  
      if (setId) {
        fetchAnalysis();
      }
    }, [setId]);
  
    // useMemo for filtered and sorted peaks
    const filteredAndSortedPeaks = useMemo(() => {
      if (!analysisData?.peak_analysis?.peak_details) {
        return [];
      }
  
      let peaks = [...analysisData.peak_analysis.peak_details];
      
      if (peakFilter) {
        const filterValue = peakFilter.toLowerCase();
        peaks = peaks.filter(peak => 
          peak.wavenumber.toString().includes(filterValue) ||
          peak.intensity.toString().includes(filterValue)
        );
      }
  
      peaks.sort((a, b) => {
        const aValue = a[peakSortField];
        const bValue = b[peakSortField];
        const modifier = peakSortDirection === 'asc' ? 1 : -1;
        return (aValue - bValue) * modifier;
      });
  
      return peaks;
    }, [analysisData?.peak_analysis?.peak_details, peakFilter, peakSortField, peakSortDirection]);
  
    const toggleAdulterant = (index) => {
      setExpandedAdulterants(prev => 
        prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
      );
    };
  
    const handleExport = () => {
      try {
        const dataStr = JSON.stringify(analysisData, null, 2);
        const blob = new Blob([dataStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `spectral-analysis-${analysisData.commodity_info.id}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
  
        toast({
          title: 'Export Successful',
          description: 'Analysis data has been downloaded as JSON',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Export Failed',
          description: 'Failed to download analysis data',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
  
    const getQualityColor = (value, threshold) => {
      if (value >= threshold) return 'green';
      if (value >= threshold * 0.8) return 'yellow';
      return 'red';
    };
  
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" h="200px">
          <Spinner size="xl" color="blue.500" />
          <Text ml={4}>Analyzing spectral data...</Text>
        </Box>
      );
    }
  
    if (error) {
      return (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      );
    }
  
    if (!analysisData) {
      return (
        <Alert status="info">
          <AlertIcon />
          <AlertDescription>No spectral data available.</AlertDescription>
        </Alert>
      );
    }
  
    return (
      <VStack spacing={6} w="full">
        <Card w="full" bg={cardBg} borderColor={borderColor}>
          <CardHeader>
            <HStack justify="space-between">
              <Heading size="md">{analysisData.commodity_info.name} Analysis</Heading>
              <HStack>
                <Tooltip label="Download analysis results as JSON">
                  <IconButton
                    icon={<DownloadIcon />}
                    onClick={handleExport}
                    aria-label="Export data"
                  />
                </Tooltip>
                <Button
                  onClick={() => setShowFullReport(!showFullReport)}
                  rightIcon={showFullReport ? <ChevronUpIcon /> : <ChevronDownIcon />}
                >
                  {showFullReport ? 'Show Summary' : 'Show Full Report'}
                </Button>
              </HStack>
            </HStack>
          </CardHeader>
  
          <CardBody>
            <VStack spacing={6} align="stretch">
              {/* Visualization Toggle */}
              <Button
                onClick={() => setShowVisualization(!showVisualization)}
                variant="ghost"
                leftIcon={showVisualization ? <ChevronUpIcon /> : <ChevronDownIcon />}
              >
                {showVisualization ? 'Hide Visualization' : 'Show Visualization'}
              </Button>
  
              {/* Spectral Visualization */}
              <Collapse in={showVisualization}>
                <SpectralPeakVisualization
                  peakData={analysisData.peak_analysis.peak_details}
                  spectralMarkers={analysisData.spectral_markers}
                />
              </Collapse>
  
              <Divider />

            <Divider />

            {/* Adulterant Analysis */}
            {analysisData.adulterant_analysis.length > 0 && (
              <Box>
                <Heading size="sm" mb={4}>
                  Potential Adulterants Detected ({analysisData.adulterant_analysis.length})
                </Heading>
                {analysisData.adulterant_analysis.map((finding, idx) => (
                  <Box key={idx} mb={4} borderWidth="1px" borderRadius="md" p={4}>
                    <HStack justify="space-between" mb={2}>
                      <HStack>
                        <Text fontWeight="medium">{finding.adulterant}</Text>
                        <Tooltip label="Confidence level based on spectral analysis">
                          <Badge colorScheme="red">
                            {(finding.confidence * 100).toFixed(1)}% confidence
                          </Badge>
                        </Tooltip>
                      </HStack>
                      <Button
                        size="sm"
                        onClick={() => toggleAdulterant(idx)}
                        variant="ghost"
                      >
                        {expandedAdulterants.includes(idx) ? 'Hide Details' : 'Show Details'}
                      </Button>
                    </HStack>
                    
                    <Collapse in={expandedAdulterants.includes(idx)}>
                      <VStack align="stretch" spacing={4} mt={4}>
                        <Text fontSize="sm" color="gray.600">
                          {finding.description}
                        </Text>
                        <Table size="sm" variant="simple">
                          <Thead>
                          <Tr>
                              <Th>Evidence Type</Th>
                              <Th isNumeric>Expected</Th>
                              <Th isNumeric>Found</Th>
                              <Th isNumeric>Intensity</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {finding.evidence.map((evidence, eidx) => (
                              <Tr key={eidx}>
                                <Td>{evidence.type}</Td>
                                <Td isNumeric>{evidence.expected}</Td>
                                <Td isNumeric>{evidence.found.toFixed(2)}</Td>
                                <Td isNumeric>{evidence.intensity.toFixed(2)}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </VStack>
                    </Collapse>
                  </Box>
                ))}
              </Box>
            )}

            {/* Quality Metrics */}
            <Box>
              <Heading size="sm" mb={4}>Quality Metrics</Heading>
              <HStack spacing={8} wrap="wrap">
                <Tooltip label="Signal-to-noise ratio indicates the clarity of the spectral data. Higher values indicate cleaner data.">
                  <Box>
                    <Text fontSize="sm">Signal to Noise Ratio</Text>
                    <Badge colorScheme={getQualityColor(analysisData.quality_metrics.signal_to_noise, 50)}>
                      {analysisData.quality_metrics.signal_to_noise.toFixed(1)}
                    </Badge>
                  </Box>
                </Tooltip>

                <Tooltip label="Number of significant peaks detected in the spectrum">
                  <Box>
                    <Text fontSize="sm">Peak Count</Text>
                    <Badge>{analysisData.quality_metrics.peak_count}</Badge>
                  </Box>
                </Tooltip>

                <Tooltip label="Measure of baseline stability. Lower values indicate better stability.">
                  <Box>
                    <Text fontSize="sm">Baseline Drift</Text>
                    <Badge colorScheme={getQualityColor(0.1, analysisData.quality_metrics.baseline_drift)}>
                      {analysisData.quality_metrics.baseline_drift.toFixed(3)}
                    </Badge>
                  </Box>
                </Tooltip>

                <Tooltip label="Average width of detected peaks at half maximum height">
                  <Box>
                    <Text fontSize="sm">Average Peak Width</Text>
                    <Badge>
                      {analysisData.quality_metrics.average_peak_width.toFixed(2)}
                    </Badge>
                  </Box>
                </Tooltip>

                <Tooltip label="Variation in peak heights across the spectrum">
                  <Box>
                    <Text fontSize="sm">Peak Height Variability</Text>
                    <Badge>
                      {analysisData.quality_metrics.peak_height_variability.toFixed(2)}
                    </Badge>
                  </Box>
                </Tooltip>
              </HStack>
            </Box>

            {/* Detailed Analysis */}
            <Collapse in={showFullReport}>
              <VStack spacing={6} align="stretch">
                {/* Spectral Markers */}
                <Box>
                  <Heading size="sm" mb={4}>Spectral Markers</Heading>
                  <Table size="sm" variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Region (cm⁻¹)</Th>
                        <Th>Type</Th>
                        <Th>Description</Th>
                        <Th>Status</Th>
                        <Th>Significance</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {analysisData.spectral_markers.map((marker, idx) => (
                        <Tr key={idx}>
                          <Td>{marker.region[0]} - {marker.region[1]}</Td>
                          <Td>{marker.type}</Td>
                          <Td>{marker.description}</Td>
                          <Td>
                            <Badge
                              colorScheme={
                                marker.status === 'normal' ? 'green' :
                                marker.status === 'high' ? 'yellow' : 'red'
                              }
                            >
                              {marker.status}
                            </Badge>
                          </Td>
                          <Td>{marker.significance}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>

                {/* Peak Analysis */}
                <Box>
                  <HStack justify="space-between" mb={4}>
                    <Heading size="sm">Peak Analysis</Heading>
                    <Button
                      size="sm"
                      onClick={() => setShowAllPeaks(!showAllPeaks)}
                    >
                      {showAllPeaks ? 'Show Major Peaks' : 'Show All Peaks'}
                    </Button>
                  </HStack>

                  {/* Peak Filtering and Sorting Controls */}
                  <HStack mb={4} spacing={4}>
                    <Input
                      placeholder="Filter peaks..."
                      value={peakFilter}
                      onChange={(e) => setPeakFilter(e.target.value)}
                      size="sm"
                      width="200px"
                    />
                    <Select
                      value={peakSortField}
                      onChange={(e) => setPeakSortField(e.target.value)}
                      size="sm"
                      width="200px"
                    >
                        <option value="wavenumber">Wavenumber</option>
                      <option value="intensity">Intensity</option>
                      <option value="prominence">Prominence</option>
                      <option value="width_50">Width (50%)</option>
                      <option value="asymmetry">Asymmetry</option>
                    </Select>
                    <Select
                      value={peakSortDirection}
                      onChange={(e) => setPeakSortDirection(e.target.value)}
                      size="sm"
                      width="150px"
                    >
                      <option value="asc">Ascending</option>
                      <option value="desc">Descending</option>
                    </Select>
                  </HStack>

                  <Table size="sm" variant="simple">
                    <Thead>
                      <Tr>
                        <Th>
                          <Tooltip label="Wavenumber position of the peak">
                            <HStack spacing={1}>
                              <Text>Wavenumber</Text>
                              <InfoIcon boxSize={3} />
                            </HStack>
                          </Tooltip>
                        </Th>
                        <Th>
                          <Tooltip label="Intensity value at peak maximum">
                            <HStack spacing={1}>
                              <Text>Intensity</Text>
                              <InfoIcon boxSize={3} />
                            </HStack>
                          </Tooltip>
                        </Th>
                        <Th>
                          <Tooltip label="Peak prominence above local baseline">
                            <HStack spacing={1}>
                              <Text>Prominence</Text>
                              <InfoIcon boxSize={3} />
                            </HStack>
                          </Tooltip>
                        </Th>
                        <Th>
                          <Tooltip label="Peak width at half maximum height">
                            <HStack spacing={1}>
                              <Text>Width (50%)</Text>
                              <InfoIcon boxSize={3} />
                            </HStack>
                          </Tooltip>
                        </Th>
                        <Th>
                          <Tooltip label="Peak asymmetry ratio">
                            <HStack spacing={1}>
                              <Text>Asymmetry</Text>
                              <InfoIcon boxSize={3} />
                            </HStack>
                          </Tooltip>
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredAndSortedPeaks
                        .slice(0, showAllPeaks ? undefined : 5)
                        .map((peak, idx) => (
                          <Tr key={idx}>
                            <Td>{peak.wavenumber.toFixed(2)}</Td>
                            <Td>{peak.intensity.toFixed(3)}</Td>
                            <Td>{peak.prominence.toFixed(3)}</Td>
                            <Td>{peak.width_50.toFixed(3)}</Td>
                            <Td>{peak.asymmetry.toFixed(3)}</Td>
                          </Tr>
                        ))}
                    </Tbody>
                  </Table>

                  {!showAllPeaks && (
                    <Alert status="info" mt={4}>
                      <AlertIcon />
                      <AlertDescription>
                        Showing top 5 peaks. Click "Show All Peaks" to view all {filteredAndSortedPeaks.length} peaks.
                      </AlertDescription>
                    </Alert>
                  )}
                </Box>

                {/* Control Sample Comparisons */}
                {analysisData.control_comparisons && analysisData.control_comparisons.length > 0 && (
                  <Box>
                    <Heading size="sm" mb={4}>Control Sample Comparisons</Heading>
                    <Table size="sm" variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Control Sample</Th>
                          <Th isNumeric>
                            <Tooltip label="Pearson correlation coefficient with control sample">
                              <HStack spacing={1} justify="flex-end">
                                <Text>Correlation</Text>
                                <InfoIcon boxSize={3} />
                              </HStack>
                            </Tooltip>
                          </Th>
                          <Th isNumeric>
                            <Tooltip label="Cosine similarity with control sample">
                              <HStack spacing={1} justify="flex-end">
                                <Text>Cosine Similarity</Text>
                                <InfoIcon boxSize={3} />
                              </HStack>
                            </Tooltip>
                          </Th>
                          <Th>Assessment</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {analysisData.control_comparisons.map((comparison, idx) => (
                          <Tr key={idx}>
                            <Td>{comparison.filename}</Td>
                            <Td isNumeric>{comparison.correlation.toFixed(3)}</Td>
                            <Td isNumeric>{comparison.cosine_similarity.toFixed(3)}</Td>
                            <Td>
                              <Badge
                                colorScheme={
                                  comparison.overall_assessment.assessment.includes('Excellent') ? 'green' :
                                  comparison.overall_assessment.assessment.includes('Good') ? 'blue' :
                                  comparison.overall_assessment.assessment.includes('Fair') ? 'yellow' : 'red'
                                }
                              >
                                {comparison.overall_assessment.assessment}
                              </Badge>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                )}
              </VStack>
            </Collapse>
          </VStack>
        </CardBody>
      </Card>
    </VStack>
  );
};

export default SpectralAnalysisTab;