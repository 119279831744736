import React, { useState, useEffect } from 'react';
import {
  Box,
  Select,
  HStack,
  VStack,
  Text,
  Skeleton,
  ButtonGroup,
  Button,
  useToken
} from '@chakra-ui/react';
import Plot from 'react-plotly.js';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const METRICS = {
  JOBS: 'jobs',
  SETS: 'sets',
  RESULTS: 'results'
};

const TIME_RANGES = {
  WEEK: '7',
  MONTH: '30',
  QUARTER: '90',
  YEAR: '365'
};

const TrendsWidget = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState(TIME_RANGES.MONTH);
  const [selectedMetric, setSelectedMetric] = useState(METRICS.JOBS);
  
  // Get Chakra UI colors for consistent theming
  const [blue500, green500, red500] = useToken('colors', ['blue.500', 'green.500', 'red.500']);

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}/api/dashboard/trends`, {
        params: { days: timeRange }
      });
      setData(response.data);
    } catch (err) {
      setError('Failed to load trends data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [timeRange]);

  if (loading) return <Skeleton height="300px" />;
  if (error) return <Text color="red.500">{error}</Text>;

  const getPlotData = () => {
    if (!data) return [];

    switch (selectedMetric) {
      case METRICS.JOBS:
        return [{
          name: 'Total Jobs',
          x: data.dates,
          y: data.jobs,
          type: 'scatter',
          mode: 'lines',
          line: { color: blue500, width: 2 },
          hovertemplate: '%{y:,.0f} jobs<extra></extra>'
        }];
      
      case METRICS.SETS:
        return [{
          name: 'Total Sets',
          x: data.dates,
          y: data.sets,
          type: 'scatter',
          mode: 'lines',
          line: { color: blue500, width: 2 },
          hovertemplate: '%{y:,.0f} sets<extra></extra>'
        }];
      
      case METRICS.RESULTS:
        return [
          {
            name: 'Typical Results',
            x: data.dates,
            y: data.typical_results,
            type: 'scatter',
            mode: 'lines',
            line: { color: green500, width: 2 },
            hovertemplate: '%{y:,.0f} typical<extra></extra>'
          },
          {
            name: 'Atypical Results',
            x: data.dates,
            y: data.atypical_results,
            type: 'scatter',
            mode: 'lines',
            line: { color: red500, width: 2 },
            hovertemplate: '%{y:,.0f} atypical<extra></extra>'
          }
        ];
      
      default:
        return [];
    }
  };

  const layout = {
    autosize: true,
    height: 250,
    margin: {
      l: 50,
      r: 20,
      t: 30,
      b: 40
    },
    xaxis: {
      type: 'date',
      tickformat: timeRange === TIME_RANGES.WEEK ? '%b %d' : 
                 timeRange === TIME_RANGES.MONTH ? '%b %d' : '%b %Y',
      gridcolor: '#E2E8F0'
    },
    yaxis: {
      title: selectedMetric === METRICS.JOBS ? 'Number of Jobs' :
             selectedMetric === METRICS.SETS ? 'Number of Sets' : 'Number of Results',
      gridcolor: '#E2E8F0',
      rangemode: 'tozero'
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      yanchor: 'bottom',
      y: 1.02,
      xanchor: 'right',
      x: 1
    },
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    hovermode: 'x unified'
  };

  const config = {
    displayModeBar: false,
    responsive: true
  };

  return (
    <VStack spacing={4} align="stretch" h="100%">
      <HStack spacing={4} justify="space-between">
        <ButtonGroup size="sm" isAttached variant="outline">
          <Button
            onClick={() => setSelectedMetric(METRICS.JOBS)}
            colorScheme={selectedMetric === METRICS.JOBS ? 'blue' : 'gray'}
          >
            Jobs
          </Button>
          <Button
            onClick={() => setSelectedMetric(METRICS.SETS)}
            colorScheme={selectedMetric === METRICS.SETS ? 'blue' : 'gray'}
          >
            Sets
          </Button>
          <Button
            onClick={() => setSelectedMetric(METRICS.RESULTS)}
            colorScheme={selectedMetric === METRICS.RESULTS ? 'blue' : 'gray'}
          >
            Results
          </Button>
        </ButtonGroup>

        <Select
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          size="sm"
          w="150px"
        >
          <option value={TIME_RANGES.WEEK}>Last 7 days</option>
          <option value={TIME_RANGES.MONTH}>Last 30 days</option>
          <option value={TIME_RANGES.QUARTER}>Last 90 days</option>
          <option value={TIME_RANGES.YEAR}>Last year</option>
        </Select>
      </HStack>

      <Box h="calc(100% - 40px)" minH="250px">
        <Plot
          data={getPlotData()}
          layout={layout}
          config={config}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
    </VStack>
  );
};

export default TrendsWidget;