import React, { useState, useEffect } from "react";
import {
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  useToast,
  VStack,
  HStack,
  FormErrorMessage,
  ModalFooter,
  Spacer,
  Checkbox,
} from "@chakra-ui/react";
import { ChevronDownIcon, AddIcon } from "@chakra-ui/icons";
import { Formik, Form, Field, useFormik } from "formik";
import * as Yup from "yup";
import Cookies from "js-cookie";
import axios from "axios";
import ModelsTable from "../components/ModelsTable";

// Constants
const PREPROCESSING_OPTIONS = [
  "SNV",
  "SNVSDGOL",
  "SDGOLSNV",
  "FDGOLSNV",
  "SNVFDGOL",
];
const MODEL_TYPES = ["pls", "opls"];

const Admin = () => {
  const [commodities, setCommodities] = useState([]);
  const [models, setModels] = useState([]);
  const {
    isOpen: isNewCommodityOpen,
    onOpen: onNewCommodityOpen,
    onClose: onNewCommodityClose,
  } = useDisclosure();
  const [modelChangeModalId, setModelChangeModalId] = useState(null);
  const [renameModalId, setRenameModalId] = useState(null);
  const [deleteControlFileModalId, setDeleteControlFileModalId] =
    useState(null);
  const {
    isOpen: isNewModelOpen,
    onOpen: onNewModelOpen,
    onClose: onNewModelClose,
  } = useDisclosure();
  const toast = useToast();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchCommodities();
    fetchModels();
  }, []);

  const fetchCommodities = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/commodities`);
      setCommodities(response.data);
    } catch (error) {
      toast({
        title: "Error fetching commodities",
        status: "error",
        duration: 3000,
      });
    }
  };

  const fetchModels = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/models`);
      setModels(response.data);
    } catch (error) {
      toast({
        title: "Error fetching models",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleCommodityAction = async (id, action, payload) => {
    try {
      await axios.post(`${apiUrl}/api/commodities/${action}`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      fetchCommodities();
      toast({
        title: "Action successful",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      toast({
        title: "Error performing action",
        status: "error",
        duration: 3000,
      });
    }
  };

  const s3Request = axios.create();
  delete s3Request.defaults.headers.common["Authorization"];

  const handleModelUpload = async (values, setSubmitting) => {
    try {
      const modelData = {
        ...values,
        filelocation: values.file.name,
      };

      const modelCreateResponse = await axios.post(
        `${apiUrl}/api/models`,
        modelData
      );

      const modelId = modelCreateResponse.data.id;

      // Upload RDS file
      const rdsResponse = await axios.post(`${apiUrl}/api/models/upload`, {
        id: modelId,
        filename: values.file.name,
      });

      await s3Request.put(rdsResponse.data.url, values.file, {
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (values.enableplotting) {
        // Upload typical plotting file
        const typicalResponse = await axios.post(
          `${apiUrl}/api/models/upload`,
          {
            id: modelId,
            filename: "typical_plotting.csv",
          }
        );

        await s3Request.put(typicalResponse.data.url, values.typical_plotting, {
          headers: {
            "Content-Type": "text/csv",
          },
        });

        // Upload atypical plotting file
        const atypicalResponse = await axios.post(
          `${apiUrl}/api/models/upload`,
          {
            id: modelId,
            filename: "atypical_plotting.csv",
          }
        );

        await s3Request.put(
          atypicalResponse.data.url,
          values.atypical_plotting,
          {
            headers: {
              "Content-Type": "text/csv",
            },
          }
        );

        delete modelData.typical_plotting;
        delete modelData.atypical_plotting;
      }

      delete modelData.file;

      fetchModels();
      onNewModelClose();
      toast({
        title: "Model created successfully",
        status: "success",
        duration: 3000,
      });
    } catch (error) {
      console.error("Error uploading model:", error);
      toast({
        title: "Error creating model",
        description: error.response?.data?.message || error.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      setSubmitting(false);
    }
  };

  // Delete control file Form
  const deleteControlFileFormik = useFormik({
    initialValues: {
      confirmDelete: "",
    },
    validationSchema: Yup.object({
      confirmDelete: Yup.string()
        .required('Please type "delete" to confirm')
        .matches(/^delete$/, 'Please type "delete" to confirm'),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await axios.delete(
          `${apiUrl}/api/commodities/controls/${deleteControlFileModalId}`
        );

        toast({
          title: "Success",
          description: "Control file deleted successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        fetchCommodities();
        setDeleteControlFileModalId(null);
      } catch (error) {
        toast({
          title: "Error",
          description:
            error.response?.data?.message || "Failed to delete control file",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setSubmitting(false);
    },
  });

  return (
    <Box p={5}>
      <Tabs>
        <TabList>
          <Tab>Commodities</Tab>
          <Tab>Models</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box mb={4}>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="blue"
                onClick={onNewCommodityOpen}
              >
                Add Commodity
              </Button>
            </Box>

            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Has Valid Control</Th>
                  <Th>Model</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {commodities.map((commodity) => (
                  <React.Fragment key={commodity.id}>
                    <Tr>
                      <Td>{commodity.name}</Td>
                      <Td>{commodity.hasValidControl ? "Yes" : "No"}</Td>
                      <Td>
                        {models.find((m) => m.id === commodity.modelId)?.name ||
                          "N/A"}
                      </Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            as={IconButton}
                            icon={<ChevronDownIcon />}
                            variant="outline"
                          />
                          <MenuList>
                            <MenuItem
                              onClick={() => setRenameModalId(commodity.id)}
                            >
                              Rename
                            </MenuItem>
                            {commodity.hasValidControl && (
                              <MenuItem
                                onClick={() =>
                                  setDeleteControlFileModalId(commodity.id)
                                }
                              >
                                Reset Control
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() =>
                                setModelChangeModalId(commodity.id)
                              }
                            >
                              Change Model
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>

                    {/* Rename Modal */}
                    <Modal
                      isOpen={renameModalId === commodity.id}
                      onClose={() => setRenameModalId(null)}
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>Rename {commodity.name}</ModalHeader>
                        <ModalCloseButton />
                        <Formik
                          initialValues={{ name: commodity.name }}
                          validationSchema={Yup.object({
                            name: Yup.string()
                              .required("Name is required")
                              .test(
                                "unique-name",
                                "This name is already in use",
                                (value) =>
                                  !commodities.some(
                                    (c) =>
                                      c.id !== commodity.id && // exclude current commodity
                                      c.name.toLowerCase() ===
                                        value?.toLowerCase() // case-insensitive comparison
                                  )
                              ),
                          })}
                          onSubmit={(values, actions) => {
                            handleCommodityAction(commodity.id, "update", {
                              id: commodity.id,
                              name: values.name,
                              modelId: commodity.modelId,
                            });
                            actions.setSubmitting(false);
                            setRenameModalId(null);
                          }}
                        >
                          {(props) => (
                            <Form>
                              <ModalBody>
                                <Field name="name">
                                  {({ field, form }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.name && form.touched.name
                                      }
                                    >
                                      <FormLabel>New Name</FormLabel>
                                      <Input
                                        {...field}
                                        placeholder="Enter new name"
                                      />
                                      <FormErrorMessage>
                                        {form.errors.name}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </ModalBody>

                              <ModalFooter>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  isLoading={props.isSubmitting}
                                  type="submit"
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="ghost"
                                  onClick={() => setRenameModalId(null)}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Form>
                          )}
                        </Formik>
                      </ModalContent>
                    </Modal>

                    {/* Model Change Modal */}
                    <Modal
                      isOpen={modelChangeModalId === commodity.id}
                      onClose={() => setModelChangeModalId(null)}
                    >
                      <ModalOverlay />
                      <ModalContent>
                        <ModalHeader>
                          Change Model for {commodity.name}
                        </ModalHeader>
                        <ModalCloseButton />
                        <Formik
                          initialValues={{ modelId: commodity.modelId || "" }}
                          validationSchema={Yup.object({
                            modelId: Yup.number().required(
                              "Please select a model"
                            ),
                          })}
                          onSubmit={(values, actions) => {
                            handleCommodityAction(commodity.id, "update", {
                              id: commodity.id,
                              name: commodity.name,
                              modelId: parseInt(values.modelId),
                            });
                            actions.setSubmitting(false);
                            setModelChangeModalId(null);
                          }}
                        >
                          {(props) => (
                            <Form>
                              <ModalBody>
                                <Field name="modelId">
                                  {({ field, form }) => (
                                    <FormControl
                                      isInvalid={
                                        form.errors.modelId &&
                                        form.touched.modelId
                                      }
                                    >
                                      <FormLabel>Select Model</FormLabel>
                                      <Select
                                        {...field}
                                        placeholder="Select a model"
                                      >
                                        {models.map((model) => (
                                          <option
                                            key={model.id}
                                            value={model.id}
                                          >
                                            {model.name}
                                          </option>
                                        ))}
                                      </Select>
                                      <FormErrorMessage>
                                        {form.errors.modelId}
                                      </FormErrorMessage>
                                    </FormControl>
                                  )}
                                </Field>
                              </ModalBody>

                              <ModalFooter>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  isLoading={props.isSubmitting}
                                  type="submit"
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="ghost"
                                  onClick={() => setModelChangeModalId(null)}
                                >
                                  Cancel
                                </Button>
                              </ModalFooter>
                            </Form>
                          )}
                        </Formik>
                      </ModalContent>
                    </Modal>
                  </React.Fragment>
                ))}
              </Tbody>
            </Table>

            {/* Delete control files modal */}
            <Modal
              isOpen={deleteControlFileModalId !== null}
              onClose={() => setDeleteControlFileModalId(null)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Delete Control File</ModalHeader>
                <ModalCloseButton />
                <form onSubmit={deleteControlFileFormik.handleSubmit}>
                  <ModalBody>
                    <FormControl
                      isInvalid={
                        deleteControlFileFormik.touched.confirmDelete &&
                        deleteControlFileFormik.errors.confirmDelete
                      }
                    >
                      <FormLabel>Type "delete" to confirm deletion</FormLabel>
                      <Input
                        name="confirmDelete"
                        onChange={deleteControlFileFormik.handleChange}
                        value={deleteControlFileFormik.values.confirmDelete}
                        placeholder="delete"
                      />
                      <FormErrorMessage>
                        {deleteControlFileFormik.errors.confirmDelete}
                      </FormErrorMessage>
                    </FormControl>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      mr={3}
                      onClick={() => setDeleteControlFileModalId(null)}
                    >
                      Cancel
                    </Button>
                    <Button
                      colorScheme="red"
                      type="submit"
                      isLoading={deleteControlFileFormik.isSubmitting}
                      isDisabled={
                        deleteControlFileFormik.values.confirmDelete !==
                        "delete"
                      }
                    >
                      Delete
                    </Button>
                  </ModalFooter>
                </form>
              </ModalContent>
            </Modal>

            <Modal isOpen={isNewCommodityOpen} onClose={onNewCommodityClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add New Commodity</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Formik
                    initialValues={{ name: "", modelId: "" }}
                    validationSchema={Yup.object({
                      name: Yup.string()
                        .required("Name is required")
                        .test(
                          "unique-name",
                          "This name is already in use",
                          (value) =>
                            !commodities.some(
                              (c) =>
                                c.name.toLowerCase() === value?.toLowerCase() // case-insensitive comparison
                            )
                        ),
                      modelId: Yup.number().required("Model is required"),
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      try {
                        await axios.post(`${apiUrl}/api/commodities`, values, {
                          headers: {
                            "Content-Type": "application/json",
                          },
                        });
                        fetchCommodities();
                        onNewCommodityClose();
                        toast({
                          title: "Commodity created successfully",
                          status: "success",
                          duration: 3000,
                        });
                      } catch (error) {
                        toast({
                          title: "Error creating commodity",
                          status: "error",
                          duration: 3000,
                        });
                      } finally {
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                    }) => (
                      <Form>
                        <VStack spacing={4}>
                          <FormControl isInvalid={errors.name && touched.name}>
                            <FormLabel>Name</FormLabel>
                            <Input
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="name"
                            />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                          </FormControl>

                          <FormControl
                            isInvalid={errors.modelId && touched.modelId}
                          >
                            <FormLabel>Model</FormLabel>
                            <Select
                              name="modelId"
                              value={values.modelId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Model</option>
                              {models.map((model) => (
                                <option key={model.id} value={model.id}>
                                  {model.name}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {errors.modelId}
                            </FormErrorMessage>
                          </FormControl>

                          <Button
                            type="submit"
                            colorScheme="blue"
                            isLoading={isSubmitting}
                            width="full"
                          >
                            Create Commodity
                          </Button>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </ModalBody>
              </ModalContent>
            </Modal>
          </TabPanel>

          <TabPanel>
            <Box mb={4}>
              <Button
                leftIcon={<AddIcon />}
                colorScheme="blue"
                onClick={onNewModelOpen}
              >
                Add Model
              </Button>
            </Box>

            <ModelsTable
              models={models}
              commodities={commodities}
              fetchModels={fetchModels}
              apiUrl={apiUrl}
            />

            <Modal isOpen={isNewModelOpen} onClose={onNewModelClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add New Model</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Formik
                    initialValues={{
                      name: "",
                      upper: "",
                      middle: "",
                      lower: "",
                      description: "",
                      preprocessing: "",
                      modeltype: "",
                      file: null,
                      enableplotting: false,
                      plot_3d: false,
                      typical_plotting: null,
                      atypical_plotting: null,
                    }}
                    validationSchema={Yup.object({
                      name: Yup.string()
                        .required("Name is required")
                        .test(
                          "unique-name",
                          "This name is already in use",
                          (value) =>
                            !models.some(
                              (c) =>
                                c.name.toLowerCase() === value?.toLowerCase() // case-insensitive comparison
                            )
                        ),
                      upper: Yup.number()
                        .min(0, "Must be between 0 and 2")
                        .max(2, "Must be between 0 and 2")
                        .required("Upper cutoff is required"),
                      middle: Yup.number()
                        .min(0, "Must be between 0 and 2")
                        .max(2, "Must be between 0 and 2")
                        .required("Middle cutoff is required"),
                      lower: Yup.number()
                        .min(0, "Must be between 0 and 2")
                        .max(2, "Must be between 0 and 2")
                        .required("Lower cutoff is required"),
                      description: Yup.string().required(
                        "Description is required"
                      ),
                      preprocessing: Yup.string().required(
                        "Preprocessing is required"
                      ),
                      modeltype: Yup.string().required(
                        "Model type is required"
                      ),
                      file: Yup.mixed()
                        .required("File is required")
                        .test(
                          "unique-name",
                          "This filename is already in use",
                          (value) =>
                            !models.some(
                              (c) =>
                                c.filename.toLowerCase() ===
                                value.name?.toLowerCase() // case-insensitive comparison
                            )
                        ),
                      enableplotting: Yup.boolean(),
                      plot_3d: Yup.boolean(),
                      typical_plotting: Yup.mixed().when("enableplotting", {
                        is: true,
                        then: () =>
                          Yup.mixed().required(
                            "Typical plotting file is required"
                          ),
                        otherwise: () => Yup.mixed().nullable(),
                      }),
                      atypical_plotting: Yup.mixed().when("enableplotting", {
                        is: true,
                        then: () =>
                          Yup.mixed().required(
                            "Atypical plotting file is required"
                          ),
                        otherwise: () => Yup.mixed().nullable(),
                      }),
                    })}
                    onSubmit={(values, { setSubmitting }) =>
                      handleModelUpload(values, setSubmitting)
                    }
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form>
                        <VStack spacing={4}>
                          <FormControl isInvalid={errors.name && touched.name}>
                            <FormLabel>Name</FormLabel>
                            <Input
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <FormErrorMessage>{errors.name}</FormErrorMessage>
                          </FormControl>

                          <HStack spacing={4} align="start">
                            <FormControl
                              isInvalid={errors.upper && touched.upper}
                            >
                              <FormLabel>Upper Cutoff</FormLabel>
                              <NumberInput max={2} min={0} precision={3}>
                                <NumberInputField
                                  name="upper"
                                  value={values.upper}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </NumberInput>
                              <FormErrorMessage>
                                {errors.upper}
                              </FormErrorMessage>
                              <Spacer />
                            </FormControl>

                            <FormControl
                              isInvalid={errors.middle && touched.middle}
                            >
                              <FormLabel>Middle Cutoff</FormLabel>
                              <NumberInput max={2} min={0} precision={3}>
                                <NumberInputField
                                  name="middle"
                                  value={values.middle}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </NumberInput>
                              <FormErrorMessage>
                                {errors.middle}
                              </FormErrorMessage>
                            </FormControl>

                            <FormControl
                              isInvalid={errors.lower && touched.lower}
                            >
                              <FormLabel>Lower Cutoff</FormLabel>
                              <NumberInput max={2} min={0} precision={3}>
                                <NumberInputField
                                  name="lower"
                                  value={values.lower}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </NumberInput>
                              <FormErrorMessage>
                                {errors.lower}
                              </FormErrorMessage>
                            </FormControl>
                          </HStack>

                          <FormControl
                            isInvalid={
                              errors.description && touched.description
                            }
                          >
                            <FormLabel>Description</FormLabel>
                            <Input
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <FormErrorMessage>
                              {errors.description}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl
                            isInvalid={
                              errors.preprocessing && touched.preprocessing
                            }
                          >
                            <FormLabel>Preprocessing</FormLabel>
                            <Select
                              name="preprocessing"
                              value={values.preprocessing}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Preprocessing</option>
                              {PREPROCESSING_OPTIONS.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {errors.preprocessing}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl
                            isInvalid={errors.modeltype && touched.modeltype}
                          >
                            <FormLabel>Model Type</FormLabel>
                            <Select
                              name="modeltype"
                              value={values.modeltype}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="">Select Model Type</option>
                              {MODEL_TYPES.map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {errors.modeltype}
                            </FormErrorMessage>
                          </FormControl>

                          <FormControl isInvalid={errors.file && touched.file}>
                            <FormLabel>RDS File</FormLabel>
                            <Input
                              type="file"
                              accept=".rds"
                              onChange={(event) => {
                                setFieldValue(
                                  "file",
                                  event.currentTarget.files[0]
                                );
                              }}
                            />
                            <FormErrorMessage>{errors.file}</FormErrorMessage>
                          </FormControl>

                          <Box
                            borderWidth="1px"
                            borderRadius="lg"
                            p={4}
                            mb={4}
                            w="full"
                          >
                            <FormControl>
                              <Checkbox
                                name="enableplotting"
                                isChecked={values.enableplotting}
                                onChange={handleChange}
                              >
                                Enable Plotting
                              </Checkbox>
                            </FormControl>

                            {values.enableplotting && (
                              <VStack spacing={4} mt={4} pl={6}>
                                <FormControl>
                                  <Checkbox
                                    name="plot_3d"
                                    isChecked={values.plot_3d}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  >
                                    Allow 3D Plotting
                                  </Checkbox>
                                </FormControl>

                                <FormControl
                                  isInvalid={
                                    errors.typical_plotting &&
                                    touched.typical_plotting
                                  }
                                >
                                  <FormLabel>Typical Plotting File</FormLabel>
                                  <Input
                                    type="file"
                                    accept=".csv"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "typical_plotting",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <FormErrorMessage>
                                    {errors.typical_plotting}
                                  </FormErrorMessage>
                                </FormControl>

                                <FormControl
                                  isInvalid={
                                    errors.atypical_plotting &&
                                    touched.atypical_plotting
                                  }
                                >
                                  <FormLabel>Atypical Plotting File</FormLabel>
                                  <Input
                                    type="file"
                                    accept=".csv"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "atypical_plotting",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                  />
                                  <FormErrorMessage>
                                    {errors.atypical_plotting}
                                  </FormErrorMessage>
                                </FormControl>
                              </VStack>
                            )}
                          </Box>

                          <Button
                            type="submit"
                            colorScheme="blue"
                            isLoading={isSubmitting}
                            width="full"
                          >
                            Create Model
                          </Button>
                        </VStack>
                      </Form>
                    )}
                  </Formik>
                </ModalBody>
              </ModalContent>
            </Modal>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default Admin;
