import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertCircle, Copy, TableIcon } from "lucide-react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Spinner,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  HStack,
  Heading,
  SimpleGrid,
  ModalFooter,
  useClipboard,
} from "@chakra-ui/react";
import { ChevronDownIcon, ArrowBackIcon } from "@chakra-ui/icons";
import {
  fetchSets,
  SET_STATUS,
  getDownloadUrl,
} from "../modules/JobApiService";
import PlotModal from "./PlotModal";
import SpectrogramModal from "./SpectrogramModal";
import ResultsModal from "./ResultsModal";

const SetsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { jobId, jobName, commodityName, userName, raised, customerRef } =
    location.state || {};
  const toast = useToast();
  const [sets, setSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGraph, setSelectedGraph] = useState({
    type: null,
    setId: null,
  });
  const [isSpectrogramModalOpen, setIsSpectrogramModalOpen] = useState(false);
  const [selectedSetId, setSelectedSetId] = useState(null);

  useEffect(() => {
    if (!jobId) {
      navigate("/portal/predictions");
    }
  }, [jobId, navigate]);

  const loadSets = async () => {
    try {
      const setsData = await fetchSets(jobId);
      setSets(setsData);
      setError(null);
    } catch (err) {
      setError("Failed to load sets");
      toast({
        title: "Error loading sets",
        description: err.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSets();
    // Set up auto-refresh
    //const intervalId = setInterval(loadSets, 60000);
    //return () => clearInterval(intervalId);
  }, [jobId]);

  const handleDownloadFiles = async (type, set) => {
    try {
      const { url, filename } = await getDownloadUrl(type, set.id);

      // Use fetch to get the file and prevent navigation
      const response = await fetch(url);
      const blob = await response.blob();

      // Create object URL from blob
      const objectUrl = window.URL.createObjectURL(blob);

      // Create and click link
      const link = document.createElement("a");
      link.href = objectUrl;
      link.download = filename;
      link.style.display = "none"; // Hide the link
      document.body.appendChild(link);
      link.click();

      // Cleanup
      setTimeout(() => {
        // Give browser time to start download
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
      }, 100);
    } catch (error) {
      toast({
        title: "Download failed",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleGenerateGraph = (type, setId, plotting, title) => {
    setSelectedGraph({ type, setId, plotting, title });
    onOpen();
  };

  const ResultCell = ({ scoreString, setId, setRefNo, statusId }) => {
    const [showFullDecimals, setShowFullDecimals] = React.useState(false);
    const [isResultsModalOpen, setIsResultsModalOpen] = React.useState(false);
    
    const canViewResults = statusId === 5;
  
    let scoreObj;
    try {
      scoreObj = scoreString ? JSON.parse(scoreString) : null;
    } catch (error) {
      console.error("Error parsing score JSON:", error);
      scoreObj = null;
    }
  
    return (
      <Box position="relative" width="100%">
        <VStack align="center" spacing={1}>
          {scoreObj ? (
            <Badge
              colorScheme={
                scoreObj.authentic_score >= scoreObj.cutoff &&
                scoreObj.authentic_score <= scoreObj.upper_cutoff
                  ? "green"
                  : "red"
              }
              p={1}
              borderRadius="md"
              cursor={canViewResults ? "pointer" : "default"}
              onClick={canViewResults ? () => setIsResultsModalOpen(true) : undefined}
              _hover={
                canViewResults
                  ? {
                      opacity: 0.8,
                      transform: "scale(1.05)",
                      transition: "all 0.2s ease-in-out",
                    }
                  : undefined
              }
            >
              {showFullDecimals
                ? scoreObj.authentic_score
                : scoreObj.authentic_score.toFixed(3)}
            </Badge>
          ) : (
            <Text fontSize="sm" align="center">
              -
            </Text>
          )}
          {scoreObj && (
            <Text fontSize="xs" color="gray.600">
              {scoreObj.cutoff.toFixed(2)} - {scoreObj.upper_cutoff.toFixed(2)}
            </Text>
          )}
        </VStack>
        
        {canViewResults && (
          <Box 
            position="absolute" 
            right="-8" 
            top="50%" 
            transform="translateY(-50%)"
          >
            <Button
              size="xs"
              variant="ghost"
              onClick={() => setIsResultsModalOpen(true)}
              aria-label="View detailed results"
            >
              <TableIcon size={14} />
            </Button>
          </Box>
        )}
        
        {/* Results Modal */}
        <ResultsModal 
          isOpen={isResultsModalOpen}
          onClose={() => setIsResultsModalOpen(false)}
          setId={setId}
          setRefNo={setRefNo}
        />
      </Box>
    );
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" height="300px">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" height="300px">
        <Text color="red.500">{error}</Text>
      </Flex>
    );
  }

  const ErrorModal = ({ isOpen, onClose, error }) => {
    const { hasCopied, onCopy } = useClipboard(error || "");

    return (
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="red.500">Error Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              p={4}
              bg="gray.50"
              borderRadius="md"
              whiteSpace="pre-wrap"
              fontFamily="mono"
            >
              {error || "No error details available"}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button leftIcon={<Copy size={16} />} onClick={onCopy} mr={3}>
              {hasCopied ? "Copied!" : "Copy Error"}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  };

  const StatusCell = ({ status, error }) => {
    console.log("StatusCell:", { status, error, isError: status === 6 });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const isError = status === 6; // Error status ID is 6

    return (
      <>
        <Badge
          colorScheme={SET_STATUS[status]?.color || "gray"}
          p={1}
          borderRadius="md"
          cursor={isError ? "pointer" : "default"}
          onClick={isError ? onOpen : undefined}
          _hover={
            isError
              ? {
                  opacity: 0.8,
                  transform: "scale(1.05)",
                  transition: "all 0.2s ease-in-out",
                }
              : undefined
          }
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
        >
          {SET_STATUS[status]?.label || "Unknown"}
          {isError && error && <AlertCircle size={14} />}
        </Badge>

        <ErrorModal isOpen={isOpen} onClose={onClose} error={error} />
      </>
    );
  };

  return (
    <Box p={4}>
      <HStack mb={6} spacing={4}>
        <Button
          leftIcon={<ArrowBackIcon />}
          onClick={() => navigate("/portal/predictions")}
          colorScheme="gray"
        >
          Back to Jobs
        </Button>
        <Heading size="lg">{jobName}</Heading>
      </HStack>

      <Box
        mb={6}
        p={4}
        borderWidth="1px"
        borderRadius="lg"
        backgroundColor="blue.50"
      >
        <SimpleGrid columns={6} spacing={4}>
          <Box>
            <Text fontSize="sm" color="gray.600" fontWeight="medium">
              Job ID
            </Text>
            <Text>{jobId}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.600" fontWeight="medium">
              Job Name
            </Text>
            <Text>{jobName}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.600" fontWeight="medium">
              Customer Reference
            </Text>
            <Text>{customerRef}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.600" fontWeight="medium">
              Commodity
            </Text>
            <Text>{commodityName}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.600" fontWeight="medium">
              Created By
            </Text>
            <Text>{userName}</Text>
          </Box>
          <Box>
            <Text fontSize="sm" color="gray.600" fontWeight="medium">
              Created On
            </Text>
            <Text>{new Date(raised).toLocaleDateString()}</Text>
          </Box>
        </SimpleGrid>
      </Box>
      <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th textAlign="center">Reference</Th>
                <Th textAlign="center">Input Files</Th>
                <Th textAlign="center">Type</Th>
                <Th textAlign="center">Status</Th>
                <Th textAlign="center">Results</Th>
                <Th textAlign="center">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sets.map((set) => (
                <Tr key={set.id}>
                  <Td>
                    <Text fontSize="sm">{set.setRefNo}</Text>
                  </Td>
                  <Td>
                    <VStack align="start" spacing={1}>
                      {[set.inputFile1, set.inputFile2, set.inputFile3]
                        .filter(Boolean)
                        .map((file, index) => (
                          <Text key={index} fontSize="sm">
                            {file.split("/").pop()}
                          </Text>
                        ))}
                    </VStack>
                  </Td>
                  <Td>
                    <Text fontSize="sm">
                      {set.isControl ? "Control" : "Sample"}
                    </Text>
                  </Td>
                  <Td>
                    <StatusCell status={set.statusId} error={set.error} />
                  </Td>
                  <Td>
  <ResultCell 
    scoreString={set.score} 
    setId={set.id}
    setRefNo={set.setRefNo}
    statusId={set.statusId}
  />
</Td>
                  <Td>
                    <HStack spacing={2}>
                      <Menu>
                        <MenuButton
                          as={Button}
                          rightIcon={<ChevronDownIcon />}
                          size="sm"
                        >
                          Downloads
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() =>
                              handleDownloadFiles("input-files", set)
                            }
                          >
                            Input Files
                          </MenuItem>
                          {set.averageFile && (
                            <MenuItem
                              onClick={() =>
                                handleDownloadFiles("average", set)
                              }
                            >
                              Average File
                            </MenuItem>
                          )}
                          {set.statusId === 5 && (
                            <MenuItem
                              onClick={() =>
                                handleDownloadFiles("results", set)
                              }
                            >
                              Results File
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>

                      <Menu>
                        <MenuButton
                          as={Button}
                          rightIcon={<ChevronDownIcon />}
                          size="sm"
                        >
                          Generate
                        </MenuButton>
                        <MenuList>
                          {set.statusId === 5 && set.enableplotting && (
                            <MenuItem
                              onClick={() =>
                                handleGenerateGraph(
                                  "2d",
                                  set.id,
                                  set.plotting,
                                  `${jobName} - Set: ${set.setRefNo} (${commodityName})`
                                )
                              }
                            >
                              2D Graph
                            </MenuItem>
                          )}
                          {set.statusId === 5 &&
                            set.plot3d &&
                            set.enableplotting && (
                              <MenuItem
                                onClick={() =>
                                  handleGenerateGraph(
                                    "3d",
                                    set.id,
                                    set.plotting
                                  )
                                }
                              >
                                3D Graph
                              </MenuItem>
                            )}
                          <MenuItem
                            onClick={() => {
                              setSelectedSetId(set.id);
                              setIsSpectrogramModalOpen(true);
                            }}
                          >
                            Spectra
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {/* Graph Modal */}
      <PlotModal
        isOpen={isOpen}
        onClose={onClose}
        selectedGraph={selectedGraph}
      />

      {/* Spectrogram Modal */}
      <SpectrogramModal
        isOpen={isSpectrogramModalOpen}
        onClose={() => setIsSpectrogramModalOpen(false)}
        setId={selectedSetId}
      />
    </Box>
  );
};

export default SetsPage;
